import { intersection, isEmpty } from 'lodash';
import { AccessLevel, UserRole } from '../interfaces/User';
import { TokenData } from '../interfaces/TokenData';

export const hasWriteAccess = (
  tokenData: TokenData,
  accessLevel: AccessLevel,
): boolean => {
  if (tokenData.roles.includes(UserRole.ADMIN)) {
    return true;
  }

  if (!tokenData.roles.includes(UserRole.WRITE)) {
    return false;
  }

  let acceptedRoles: UserRole[] = [];
  switch (accessLevel) {
    case AccessLevel.INSTITUTION: {
      acceptedRoles = [UserRole.INSTITUTION_ACCESS];
      break;
    }
    case AccessLevel.DEPARTMENT: {
      acceptedRoles = [UserRole.INSTITUTION_ACCESS, UserRole.DEPARTMENT_ACCESS];
      break;
    }
    case AccessLevel.DEPARTMENT_SECTION: {
      // No specific role is required
      acceptedRoles = [UserRole.WRITE];
      break;
    }
    default:
      break;
  }

  return !isEmpty(intersection(acceptedRoles, tokenData.roles));
};

export const hasInstitutionAccess = (tokenData: TokenData) =>
  tokenData.roles.includes(UserRole.ADMIN) ||
  tokenData.roles.includes(UserRole.INSTITUTION_ACCESS);
