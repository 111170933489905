import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { toast } from 'material-react-toastify';
import { restoreSchedule } from '../../services/Schedule';
import { sharedStyles } from '../../utils/Style';
import Waiting from '../Waiting';

interface RestoreScheduleDialogProps {
  scheduleID: number;
  open: boolean;
  onRollback: () => void;
  onClose: () => void;
}

const RestoreScheduleDialog = (props: RestoreScheduleDialogProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleRestore = (overwrite: boolean) => {
    setLoading(true);
    restoreSchedule(props.scheduleID, overwrite)
      .then(() => {
        toast.success(t('schedule.restore.successful_message'));
        props.onRollback();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Waiting open={loading} />
      <DialogTitle>{t('schedule.restore.dialog_title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('schedule.restore.dialog_content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          onClick={() => handleRestore(true)}
          color='primary'
          sx={sharedStyles.buttonText}
        >
          {t('schedule.restore.overwrite')}
        </Button>
        <Button
          variant='contained'
          onClick={() => handleRestore(false)}
          color='primary'
          sx={sharedStyles.buttonText}
        >
          {t('schedule.restore.new_record')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreScheduleDialog;
