import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { DropzoneArea } from 'react-mui-dropzone';
import { createStyles, makeStyles } from '@mui/styles';
import { sharedColors, sharedStyles } from '../../utils/Style';

interface AddScheduleNotesDialogProps {
  title: string;
  content: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (notes: string, files: File[]) => void;
}

const AddScheduleNotesDialog = (props: AddScheduleNotesDialogProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [notes, setNotes] = useState('');
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    setNotes('');
    setFiles([]);
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <DialogContentText>{props.content}</DialogContentText>
        <TextField
          label={t('schedule.description')}
          value={notes}
          onChange={(e) => setNotes(e.target?.value ?? '')}
          size='small'
          fullWidth
          multiline
          sx={{ mt: 1.5 }}
        />
        <Accordion sx={{ mt: 1.5 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box component='div' sx={{ display: 'flex', flexDirection: 'row' }}>
              <PostAddIcon sx={{ mt: 'auto', mb: 0.3 }} />
              <Typography sx={{ mt: 'auto', mb: 'auto', ml: 1.25 }}>
                {t('schedule.update_notes.attachments')}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              component='fieldset'
              sx={{ display: 'flex', flexGrow: 1 }}
            >
              <DropzoneArea
                dropzoneClass={classes.dropzone}
                dropzoneText={t('upload.title_multiple') as string}
                filesLimit={100}
                showFileNames
                onChange={setFiles}
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('confirm_dialog.cancel')}
        </Button>
        <Button
          variant='contained'
          onClick={() => props.onConfirm(notes, files)}
          autoFocus
          color='primary'
          sx={sharedStyles.buttonText}
        >
          {t('confirm_dialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    dropzone: {
      position: 'relative',
      padding: 2,
      minHeight: '100px',
      backgroundColor: sharedColors.gray2,
      border: 'dashed',
      borderColor: sharedColors.gray3,
      cursor: 'pointer',
    },
  }),
);

export default AddScheduleNotesDialog;
