import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box } from '@mui/material';
import axios from 'axios';
import { selectedPageAtom } from '../../atoms/Page';
import { PageType } from '../../interfaces/Page';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import ScheduleTable from './ScheduleTable';
import { getDepartments } from '../../services/Department';
import { Department } from '../../interfaces/Department';
import {
  ScheduleGetRequest,
  ScheduleList,
  ScheduleSortableFields,
  ScheduleSummary,
} from '../../interfaces/Schedule';
import { getSchedules, getScheduleSummary } from '../../services/Schedule';
import { Institution } from '../../interfaces/Institution';
import { getInstitutions } from '../../services/Institution';
import { Pagination } from '../../interfaces/Pagination';

const SchedulePage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [scheduleSummaries, setScheduleSummaries] = useState<ScheduleSummary[]>(
    [],
  );
  const [schedules, setSchedules] = useState<ScheduleList>({
    totalCount: 0,
    schedules: [],
  });

  useEffect(() => {
    setSelectedPage(PageType.SCHEDULE);

    refreshAll({ pageNumber: 0, pageSize: 10 });
  }, []);

  const refreshAll = (pagination: Pagination) => {
    setLoading(true);

    Promise.all([
      getSchedules({
        pagination,
        sort: {
          sortFieldName: ScheduleSortableFields.LAST_UPDATED_AT,
          isDescending: true,
        },
      }),
      getDepartments(),
      getInstitutions(),
      getScheduleSummary(),
    ])
      .then(
        axios.spread(
          // @ts-ignore
          (
            schedulesRes: ScheduleList,
            departmentsRes: Department[],
            institutionsRes: Institution[],
            scheduleSummariesRes: ScheduleSummary[],
          ) => {
            setSchedules(schedulesRes);
            setDepartments(departmentsRes);
            setInstitutions(institutionsRes);
            setScheduleSummaries(scheduleSummariesRes);
          },
        ),
      )
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const refreshSchedules = (request: ScheduleGetRequest) => {
    setLoading(true);

    getSchedules(request)
      .then((fetchedSchedules) => setSchedules(fetchedSchedules))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Box component='div' sx={sharedStyles.pageBody}>
        <ScheduleTable
          institutions={institutions}
          departments={departments}
          scheduleList={schedules}
          onChange={refreshAll}
          refreshSchedules={refreshSchedules}
          scheduleSummaries={scheduleSummaries}
        />
      </Box>
    </Box>
  );
};

export default SchedulePage;
