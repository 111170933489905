import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { AlgorithmParameterMultipliers } from '../../interfaces/AlgorithmParameterMultipliers';

interface AdvancedSettingsProps {
  algorithmParameterMultipliers: AlgorithmParameterMultipliers;
  onChange: (newParameters: AlgorithmParameterMultipliers) => void;
}

const overtimeMarks = [
  {
    value: 1,
    label: t('schedule.prioritization.normal'),
  },
  {
    value: 1.25,
    label: t('schedule.prioritization.high'),
  },
  {
    value: 1.5,
    label: t('schedule.prioritization.very_high'),
  },
  {
    value: 1.75,
    label: t('schedule.prioritization.max'),
  },
];

const fullDayMarks = [
  {
    value: 1,
    label: t('schedule.prioritization.normal'),
  },
  {
    value: 1.1,
    label: t('schedule.prioritization.high'),
  },
  {
    value: 1.2,
    label: t('schedule.prioritization.very_high'),
  },
  {
    value: 1.3,
    label: t('schedule.prioritization.max'),
  },
];

const AdvancedSettingsDialog = (props: AdvancedSettingsProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [multipliers, setMultipliers] = useState<AlgorithmParameterMultipliers>(
    props.algorithmParameterMultipliers,
  );

  return (
    <React.Fragment>
      <Button
        variant='outlined'
        onClick={() => setOpen(true)}
        sx={{ textTransform: 'none' }}
        size={'large'}
      >
        {t('schedule.advanced_settings')}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setMultipliers(props.algorithmParameterMultipliers);
          setOpen(false);
        }}
        PaperProps={{
          style: {
            width: 600,
            height: 500,
          },
        }}
      >
        <DialogTitle fontSize={'medium'}>
          {t('schedule.advanced_settings')}
        </DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column' }}
          dividers
        >
          <Box sx={{ width: 500, pl: 2 }}>
            <Box sx={{ fontWeight: 'bold' }}>
              <Typography>{`${t(
                'schedule.overtime_hours_equality',
              )}:`}</Typography>
            </Box>
            <Slider
              size='small'
              value={multipliers.overtimeHours}
              onChange={(_, newValue) =>
                setMultipliers({
                  ...multipliers,
                  overtimeHours: newValue as number,
                })
              }
              valueLabelDisplay='off'
              marks={overtimeMarks}
              step={null}
              min={1}
              max={1.75}
            />
          </Box>
          <Box sx={{ width: 500, pl: 2, pt: 2 }}>
            <Typography>{`${t(
              'schedule.non_fullday_night_punish',
            )}:`}</Typography>
            <Slider
              size='small'
              value={multipliers.nonFullDayNight}
              onChange={(_, newValue) =>
                setMultipliers({
                  ...multipliers,
                  nonFullDayNight: newValue as number,
                })
              }
              valueLabelDisplay='off'
              marks={fullDayMarks}
              step={null}
              min={1}
              max={1.3}
            />
          </Box>
          <Box sx={{ width: 500, pl: 2, pt: 2 }}>
            <Typography>{`${t('schedule.fullday_night_reward')}:`}</Typography>
            <Slider
              size='small'
              value={multipliers.fullDayNight}
              onChange={(_, newValue) =>
                setMultipliers({
                  ...multipliers,
                  fullDayNight: newValue as number,
                })
              }
              valueLabelDisplay='off'
              marks={fullDayMarks}
              step={null}
              min={1}
              max={1.3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onChange(multipliers);
              setOpen(false);
            }}
          >
            {t('shared.finalize')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AdvancedSettingsDialog;
