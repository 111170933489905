import { getStoredLanguage } from './Language';

export const formatToLimitedDecimals = (
  value: number,
  maxDecimals?: number,
): string =>
  value.toLocaleString(getStoredLanguage(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimals ?? 2,
  });
