import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ChevronRight } from '@mui/icons-material';
import {
  PersonnelOnDuty,
  PersonnelOnDutyRequest,
} from '../../interfaces/PersonnelOnDuty';
import { Institution } from '../../interfaces/Institution';
import { Department } from '../../interfaces/Department';
import { Pagination } from '../../interfaces/Pagination';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import { SortCriteria } from '../../interfaces/SortCriteria';
import { getPriorityByOrder } from '../../utils/Sort';
import { comparePersonnelsOnDuty } from '../../utils/PersonnelOnDuty';
import { sharedColors, sharedStyles } from '../../utils/Style';
import InstitutionAutocomplete from '../Institution/InstitutionAutocomplete';
import DepartmentAutocomplete from '../Department/DepartmentAutocomplete';
import DepartmentSectionAutocomplete from '../DepartmentSection/DepartmentSectionAutocomplete';
import SortableTableCell from '../SortableTableCell';
import { getDatePickerLocale } from '../../utils/Date';
import { Shift } from '../../interfaces/Shift';
import ShiftDropdown from '../ShiftDropdown';
import { occupiedShifts } from '../../variables/Schedule';

interface PersonnelOnDutyTableProps {
  personnels: PersonnelOnDuty[];
  institutions: Institution[];
  departments: Department[];
  onFilter: (req: PersonnelOnDutyRequest) => void;
}

const PersonnelOnDutyTable = (props: PersonnelOnDutyTableProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [searchedDate, setSearchedDate] = useState(new Date());
  const [searchedInstitution, setSearchedInstitution] =
    useState<Institution | null>(null);
  const [searchedDepartment, setSearchedDepartment] =
    useState<Department | null>(null);
  const [searchedDepartmentSection, setSearchedDepartmentSection] =
    useState<DepartmentSection | null>(null);
  const [searchedShift, setSearchedShift] = useState(Shift.ALL_SHIFTS);
  const [sortCriteria, setSortCriteria] = useState<
    SortCriteria<PersonnelOnDuty>
  >({
    fieldName: 'name',
    asc: true,
  });

  useEffect(() => {
    setPagination({ ...pagination, pageNumber: 0 });
  }, [props.personnels]);

  useEffect(() => {
    setSearchedDepartment(null);
  }, [searchedInstitution]);

  useEffect(() => {
    setSearchedDepartmentSection(null);
  }, [searchedDepartment]);

  const handleSort = (fieldName: keyof PersonnelOnDuty) => {
    setSortCriteria({
      fieldName,
      asc: fieldName === sortCriteria.fieldName ? !sortCriteria.asc : true,
    });
  };

  const handleSearch = () => {
    props.onFilter({
      day: searchedDate,
      institutionID: searchedInstitution?.ID,
      departmentID: searchedDepartment?.ID,
      departmentSectionID: searchedDepartmentSection?.ID,
      shift: searchedShift === Shift.ALL_SHIFTS ? undefined : searchedShift,
    });
  };

  let filteredRows = props.personnels;
  filteredRows = filteredRows.sort((p1, p2) =>
    comparePersonnelsOnDuty(p1, p2, sortCriteria.fieldName) < 0
      ? getPriorityByOrder(sortCriteria.asc)
      : -getPriorityByOrder(sortCriteria.asc),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {t('personnel_on_duty.title')}
      </Typography>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          mt: 1.5,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={getDatePickerLocale()}
        >
          <DatePicker
            label={t('personnel_on_duty.date')}
            value={searchedDate}
            inputFormat='d MMMM yyyy'
            onChange={(newValue: Date | null) =>
              setSearchedDate(newValue ?? new Date())
            }
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...params}
                size='small'
                sx={{
                  width: 175,
                  backgroundColor: sharedColors.white,
                }}
              />
            )}
          />
        </LocalizationProvider>
        <Box component='div' sx={{ mr: 1.5, ml: 1.5 }}>
          <InstitutionAutocomplete
            label={t('personnel_on_duty.institution') as string}
            width={200}
            options={props.institutions}
            value={searchedInstitution}
            onChange={setSearchedInstitution}
            backgroundColor={sharedColors.white}
          />
        </Box>
        <DepartmentAutocomplete
          label={t('personnel_on_duty.department') as string}
          disabled={!searchedInstitution}
          options={props.departments.filter(
            (department) =>
              !!searchedInstitution &&
              department.institutionID === searchedInstitution.ID,
          )}
          value={searchedDepartment}
          onChange={setSearchedDepartment}
          width={200}
          backgroundColor={sharedColors.white}
        />
        <Box component='div' sx={{ mr: 1.5 }} />
        <DepartmentSectionAutocomplete
          label={t('personnel_on_duty.department_section') as string}
          options={searchedDepartment?.departmentSections ?? []}
          value={searchedDepartmentSection}
          disabled={!searchedDepartment}
          onChange={setSearchedDepartmentSection}
          width={200}
          backgroundColor={sharedColors.white}
        />
        <Box component='div' sx={{ mr: 1.5 }} />
        <ShiftDropdown
          label={t('personnel_on_duty.shift') as string}
          width={140}
          backgroundColor={sharedColors.white}
          options={[Shift.ALL_SHIFTS, ...occupiedShifts]}
          value={searchedShift}
          onChange={setSearchedShift}
        />
        <Button
          color='primary'
          endIcon={<ChevronRight />}
          onClick={handleSearch}
          sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto', ml: 1.5 }}
        >
          {t('shared.search')}
        </Button>
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='name'
                onSort={() => handleSort('name')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_on_duty.name')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='position'
                onSort={() => handleSort('position')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_on_duty.position')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='shift'
                onSort={() => handleSort('shift')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_on_duty.shift')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='institutionName'
                onSort={() => handleSort('institutionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_on_duty.institution')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentName'
                onSort={() => handleSort('departmentName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_on_duty.department')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departmentSectionName'
                onSort={() => handleSort('departmentSectionName')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('personnel_on_duty.department_section')}
                </Typography>
              </SortableTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {t(`personnel_position.${row.position}`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {t(`schedule.shifts.${row.shift}`)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.institutionName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.departmentName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.departmentSectionName}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
    </Box>
  );
};

export default PersonnelOnDutyTable;
