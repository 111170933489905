import { useTranslation } from 'react-i18next';
import { Box, Link, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  ScheduleNotes,
  ScheduleNotesType,
} from '../../interfaces/ScheduleNotes';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { formatLocalTime } from '../../utils/Date';
import ChangeNotesTooltip from './ChangeNotesTooltip';
import { downloadDocument } from '../../services/Document';
import Waiting from '../Waiting';

interface ScheduleNotesCardProps {
  scheduleNotes: ScheduleNotes;
}

const ScheduleNotesCard = (props: ScheduleNotesCardProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleDownloadAttachment = (filename: string) => {
    setLoading(true);
    downloadDocument(filename)
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const getOperationRow = () => {
    switch (props.scheduleNotes.type) {
      case ScheduleNotesType.CHANGES:
        return (
          <Typography
            sx={{
              color: sharedColors.gray6,
              ...sharedStyles.body1,
              fontWeight: 'bold',
            }}
          >
            {t('schedule.notes_dialog.operation', {
              operation: t('schedule.notes_type.CHANGES', {
                n: (
                  props.scheduleNotes.changes.length +
                  props.scheduleNotes.overtimeChanges.length
                ).toString(),
              }),
            })}
          </Typography>
        );

      case ScheduleNotesType.APPROVAL:
        return (
          <Typography
            sx={{
              color: sharedColors.statusGreen,
              ...sharedStyles.body1,
              fontWeight: 'bold',
            }}
          >
            {t('schedule.notes_dialog.operation', {
              operation: t('schedule.notes_type.APPROVAL'),
            })}
          </Typography>
        );
      case ScheduleNotesType.REJECTION:
        return (
          <Typography
            sx={{
              color: sharedColors.statusRed,
              ...sharedStyles.body1,
              fontWeight: 'bold',
            }}
          >
            {t('schedule.notes_dialog.operation', {
              operation: t('schedule.notes_type.REJECTION'),
            })}
          </Typography>
        );
      case ScheduleNotesType.APPROVAL_ROLLBACK:
        return (
          <Typography
            sx={{
              color: sharedColors.statusOrange,
              ...sharedStyles.body1,
              fontWeight: 'bold',
            }}
          >
            {t('schedule.notes_dialog.operation', {
              operation: t('schedule.notes_type.APPROVAL_ROLLBACK'),
            })}
          </Typography>
        );
      default:
        return <></>;
    }
  };

  const renderContent = () => {
    return (
      <Box component='div'>
        <Waiting open={loading} />
        <Typography
          sx={{
            ...sharedStyles.subtitle2,
            color: sharedColors.gray5,
          }}
        >
          {t('schedule.notes_dialog.audit', {
            date: formatLocalTime(t, props.scheduleNotes.audit.createdAt),
            username: props.scheduleNotes.audit.createdBy,
          })}
        </Typography>
        {getOperationRow()}
        <Typography
          sx={{
            color: sharedColors.gray6,
            ...sharedStyles.body1,
          }}
        >
          {t('schedule.notes_dialog.notes', {
            notes: props.scheduleNotes.notes.split('\n')[0],
          })}
        </Typography>
        {props.scheduleNotes.notes
          .split('\n')
          .slice(1)
          .map((row) => (
            <Typography
              sx={{
                color: sharedColors.gray6,
                ...sharedStyles.body1,
              }}
            >
              {row}
            </Typography>
          ))}
        <Box component='div' sx={{ display: 'flex', flexDirection: 'column' }}>
          {props.scheduleNotes.attachments.map((filename) => (
            <Link
              onClick={() => handleDownloadAttachment(filename)}
              sx={{ fontSize: 12, mt: 0.25, cursor: 'pointer' }}
            >
              {`${filename} ↓`}
            </Link>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        mt: 1,
        mb: 0.5,
        p: 1,
        backgroundColor: sharedColors.gray2,
      }}
    >
      {props.scheduleNotes.type === ScheduleNotesType.CHANGES ? (
        <ChangeNotesTooltip
          shiftChanges={props.scheduleNotes.changes}
          overtimeChanges={props.scheduleNotes.overtimeChanges}
        >
          {renderContent()}
        </ChangeNotesTooltip>
      ) : (
        renderContent()
      )}
    </Paper>
  );
};

export default ScheduleNotesCard;
