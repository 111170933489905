import { Box, Button, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import React from 'react';
import { FileExportType } from '../interfaces/FileExport';
import { possibleFileExportTypes } from '../variables/FileExport';

interface DownloadMenuProps {
  id?: string;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  onDownload: (fileExportType: FileExportType) => void;
}

const DownloadMenu = (props: DownloadMenuProps) => {
  const { t } = useTranslation();

  return (
    <Popover
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      disableScrollLock
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box component='div' sx={{ display: 'flex', flexDirection: 'column' }}>
        {possibleFileExportTypes.map((exportType) => (
          <Button
            startIcon={<TextSnippetIcon />}
            onClick={() => props.onDownload(exportType)}
            sx={{
              padding: 1.5,
              textTransform: 'none',
              justifyContent: 'flex-start',
            }}
          >
            {t(`export.${exportType}`)}
          </Button>
        ))}
      </Box>
    </Popover>
  );
};

export default DownloadMenu;
