import Keycloak from 'keycloak-js';
import { TokenData } from '../interfaces/TokenData';
import { UserRole } from '../interfaces/User';

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
});

export const getStoredKeycloakToken = (): string | null =>
  localStorage.getItem('keycloakToken');

export const storeKeycloakToken = (token?: string) => {
  if (token) {
    localStorage.setItem('keycloakToken', token);
  } else {
    localStorage.removeItem('keycloakToken');
  }
};

export const getTokenDataFromKeyCloak = (kc: Keycloak): TokenData => {
  const token = kc.tokenParsed ?? {};

  return {
    username: token.preferred_username,
    roles: (kc.realmAccess?.roles ?? []) as UserRole[],
    institution: token.institution ?? [],
    department: token.department ?? [],
    departmentSection: token.department_section ?? [],
  };
};

export default keycloak;
