import { Institution } from '../interfaces/Institution';
import { getStoredLanguage } from './Language';
import { turkishCities } from '../variables/City';

export const getInstitutionTemplate = (): Institution => ({
  name: '',
  city: turkishCities[0].name,
  departments: [],
  totalDepartments: 0,
  totalDepartmentSections: 0,
  totalPersonnels: 0,
});

export const compareInstitutions = (
  i1: Institution,
  i2: Institution,
  fieldName: keyof Institution,
): number => {
  const locale = getStoredLanguage();

  switch (fieldName) {
    case 'name':
      return i1.name.localeCompare(i2.name, locale);
    case 'city':
      return i1.city.localeCompare(i2.city, locale);
    case 'departments':
      return i1.departments.length - i2.departments.length;
    case 'totalDepartmentSections':
      return i1.totalDepartmentSections - i2.totalDepartmentSections;
    case 'totalPersonnels':
      return i1.totalPersonnels - i2.totalPersonnels;
    default:
      return (i1.ID ?? 0) - (i2.ID ?? 0);
  }
};
