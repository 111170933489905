import {
  DepartmentSection,
  departmentSectionFromResponse,
} from './DepartmentSection';

export interface DepartmentPersonnelCounts {
  day: number;
  night: number;
  weekend: number;
}

const departmentPersonnelCountsFromResponse = (
  responseData: any,
): DepartmentPersonnelCounts => ({
  day: responseData.day,
  night: responseData.night,
  weekend: responseData.weekend,
});

const departmentPersonnelCountsOutputTransformer = (
  departmentPersonnelCounts: DepartmentPersonnelCounts,
) => ({
  day: departmentPersonnelCounts.day,
  night: departmentPersonnelCounts.night,
  weekend: departmentPersonnelCounts.weekend,
});

export interface Department {
  ID?: number;
  institutionID: number;
  institutionName: string;
  name: string;
  minimumPersonnelCounts: DepartmentPersonnelCounts;
  minimumSeniorPersonnelCounts: DepartmentPersonnelCounts;
  totalDepartmentSections: number;
  totalPersonnels: number;
  departmentSections: DepartmentSection[];
}

export const departmentFromResponse = (responseData: any): Department => ({
  ID: responseData.id,
  institutionID: responseData.institution_id,
  institutionName: responseData.institution_name,
  name: responseData.name,
  minimumPersonnelCounts: departmentPersonnelCountsFromResponse(
    responseData.minimum_personnel_counts,
  ),
  minimumSeniorPersonnelCounts: departmentPersonnelCountsFromResponse(
    responseData.minimum_senior_personnel_counts,
  ),
  totalDepartmentSections: responseData.total_department_sections,
  totalPersonnels: responseData.total_personnels,
  departmentSections: responseData.department_sections.map((section: any) =>
    departmentSectionFromResponse(section),
  ),
});

export const departmentOutputTransformer = (department: Department) => ({
  id: department.ID,
  institution_id: department.institutionID,
  institution_name: department.institutionName,
  name: department.name,
  minimum_personnel_counts: departmentPersonnelCountsOutputTransformer(
    department.minimumPersonnelCounts,
  ),
  minimum_senior_personnel_counts: departmentPersonnelCountsOutputTransformer(
    department.minimumSeniorPersonnelCounts,
  ),
});
