import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box } from '@mui/material';
import axios from 'axios';
import { selectedPageAtom } from '../../atoms/Page';
import { PageType } from '../../interfaces/Page';
import { getPersonnels } from '../../services/Personnel';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import PersonnelTable from './PersonnelTable';
import { Personnel } from '../../interfaces/Personnel';
import { getDepartments } from '../../services/Department';
import { Department } from '../../interfaces/Department';
import { Institution } from '../../interfaces/Institution';
import { getInstitutions } from '../../services/Institution';

const PersonnelPage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loadingPersonnels, setLoadingPersonnels] = useState(false);
  const [personnels, setPersonnels] = useState<Personnel[]>([]);
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    setSelectedPage(PageType.PERSONNEL);

    refreshPersonnels();
  }, []);

  const refreshPersonnels = () => {
    setLoadingPersonnels(true);
    axios
      // @ts-ignore
      .all([getPersonnels(), getDepartments(), getInstitutions()])
      .then(
        axios.spread(
          // @ts-ignore
          (
            personnelRes: Personnel[],
            departmentRes: Department[],
            institutionsRes: Institution[],
          ) => {
            setPersonnels(personnelRes);
            setDepartments(departmentRes);
            setInstitutions(institutionsRes);
          },
        ),
      )
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingPersonnels(false));
  };

  return (
    <Box component='div'>
      <Waiting open={loadingPersonnels} />
      <Box component='div' sx={sharedStyles.pageBody}>
        <PersonnelTable
          personnels={personnels}
          institutions={institutions}
          departments={departments}
          onChange={refreshPersonnels}
        />
      </Box>
    </Box>
  );
};

export default PersonnelPage;
