import axios from 'axios';
import { getStoredKeycloakToken } from '../utils/Keycloak';
import { getStoredLanguage } from '../utils/Language';
import { Language } from '../interfaces/Language';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use((request) => {
  const token = getStoredKeycloakToken();
  if (token) {
    request.headers.set('Authorization', `Bearer ${token}`);
  }

  return request;
});

api.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err?.response?.data?.message) {
      throw new Error(err.response.data.message);
    }
    if (err?.response?.data?.messages) {
      const messages = err.response.data.messages;
      throw new Error(
        messages[getStoredLanguage()] ??
          messages[Language.TR] ??
          'Unknown API error.',
      );
    }
    throw new Error('Unknown API error.');
  },
);

export default api;
