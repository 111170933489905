import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import { Department } from '../../interfaces/Department';
import { getDepartmentSectionTemplate } from '../../utils/DepartmentSection';
import { upsertDepartmentSection } from '../../services/DepartmentSection';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { Institution } from '../../interfaces/Institution';
import InstitutionDropdown from '../Institution/InstitutionDropdown';

interface UpsertDepartmentSectionDialogProps {
  open: boolean;
  clickedDepartmentSection?: DepartmentSection;
  institutions: Institution[];
  departments: Department[];
  onClose: () => void;
  onUpsert: () => void;
}

const UpsertDepartmentSectionDialog = (
  props: UpsertDepartmentSectionDialogProps,
) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(
    getDepartmentSectionTemplate(),
  );

  useEffect(() => {
    setCurrentState(
      props.clickedDepartmentSection
        ? cloneDeep(props.clickedDepartmentSection)
        : getDepartmentSectionTemplate(),
    );
  }, [props.clickedDepartmentSection, props.open]);

  const handleConfirm = () => {
    setLoading(true);

    upsertDepartmentSection(currentState)
      .then(() => {
        toast.success(
          props.clickedDepartmentSection
            ? t('department_section.successful.update')
            : t('department_section.successful.add'),
        );
        props.onUpsert();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeName = (e: any) => {
    setCurrentState({ ...currentState, name: e.target.value });
  };

  const handleChangeInstitution = (newInstitution: Institution) => {
    setCurrentState({
      ...currentState,
      institutionID: newInstitution.ID!,
      institutionName: newInstitution.name,
      departmentID: newInstitution.departments[0]?.ID ?? 0,
      departmentName: newInstitution.departments[0]?.name ?? '',
    });
  };

  const handleChangeDepartment = (e: any) => {
    const department = props.departments.find(
      (x) => x.name === e.target.value,
    )!;

    setCurrentState({
      ...currentState,
      departmentName: e.target.value,
      departmentID: department.ID!,
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={false}
      PaperProps={{
        style: {
          width: 350,
        },
      }}
    >
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {props.clickedDepartmentSection
          ? t('department_section.dialog.update')
          : t('department_section.dialog.add')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box component='div' sx={{ mt: 1.5 }}>
          <InstitutionDropdown
            label={t('department_section.institution') as string}
            backgroundColor={sharedColors.white}
            options={props.institutions}
            disabled={!!props.clickedDepartmentSection}
            value={currentState.institutionID}
            onChange={handleChangeInstitution}
          />
        </Box>
        <FormControl size='small' fullWidth sx={{ mt: 1.5 }}>
          <InputLabel>{t('department_section.department')}</InputLabel>
          <Select
            value={currentState.departmentName}
            label={t('department_section.department')}
            disabled={!!props.clickedDepartmentSection}
            onChange={handleChangeDepartment}
            name='select'
            required
          >
            {props.departments
              .filter(
                (department) =>
                  department.institutionID === currentState.institutionID,
              )
              .map((department) => (
                <MenuItem value={department.name} key={department.ID}>
                  {department.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label={t('department_section.name')}
          value={currentState.name}
          onChange={handleChangeName}
          size='small'
          fullWidth
          error={isEmpty(currentState.name)}
          sx={{ mt: 1.5 }}
        />
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            isEmpty(currentState.name) || isEmpty(currentState.departmentName)
          }
          onClick={handleConfirm}
          sx={sharedStyles.buttonText}
        >
          {props.clickedDepartmentSection
            ? t('department_section.dialog.update')
            : t('department_section.dialog.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertDepartmentSectionDialog;
