import { Pagination } from './Pagination';

export enum UserRole {
  ADMIN = 'ADMIN',
  INSTITUTION_ACCESS = 'INSTITUTION_ACCESS',
  DEPARTMENT_ACCESS = 'DEPARTMENT_ACCESS',
  WRITE = 'WRITE',
  EXPERIMENTAL_ALGORITHM = 'EXPERIMENTAL_ALGORITHM',
}

export enum AccessLevel {
  ADMIN = 'ADMIN',
  INSTITUTION = 'INSTITUTION',
  DEPARTMENT = 'DEPARTMENT',
  DEPARTMENT_SECTION = 'DEPARTMENT_SECTION',
}

export interface UserGetRequest extends Pagination {
  searchedValue?: string;
  institutionID: number;
  departmentID?: number;
  departmentSectionID?: number;
}

export const userGetRequestOutputTransformer = (req: UserGetRequest) => ({
  page_size: req.pageSize,
  page_number: req.pageNumber,
  searched_value: req.searchedValue,
  institution_id: req.institutionID,
  department_id: req.departmentID,
  department_section_id: req.departmentSectionID,
});

interface UserBase {
  firstName: string;
  lastName: string;
  email: string;
  roles: UserRole[];
  institutions: string[];
  departments: string[];
  departmentSections: string[];
}

const getUserBaseFields = <T extends UserBase>(u: T): UserBase => ({
  firstName: u.firstName,
  lastName: u.lastName,
  email: u.email,
  roles: u.roles,
  institutions: u.institutions,
  departments: u.departments,
  departmentSections: u.departmentSections,
});

const userBaseOutputTransformer = (req: UserBase) => ({
  first_name: req.firstName,
  last_name: req.lastName,
  email: req.email,
  roles: req.roles,
  institutions: req.institutions,
  departments: req.departments,
  department_sections: req.departmentSections,
});

export interface UserUpdateRequest extends UserBase {}

export const userUpdateRequestFromCreateRequest = (
  req: UserCreateRequest,
): UserUpdateRequest => ({
  ...getUserBaseFields(req),
});

export const userUpdateRequestOutputTransformer = (req: UserUpdateRequest) =>
  userBaseOutputTransformer(req);

export interface UserCreateRequest extends UserBase {
  username: string;
  password: string;
  isTempPassword: boolean;
}

export const userCreateRequestFromUser = (user: User): UserCreateRequest => ({
  ...getUserBaseFields(user),
  username: user.username,
  password: '',
  isTempPassword: false,
});

export const userCreateRequestOutputTransformer = (req: UserCreateRequest) => ({
  ...userBaseOutputTransformer(req),
  username: req.username,
  password: req.password,
  is_temp_password: req.isTempPassword,
});

export interface User extends UserBase {
  id: string;
  username: string;
}

export const userFromResponse = (responseData: any): User => ({
  id: responseData.id,
  firstName: responseData.first_name,
  lastName: responseData.last_name,
  email: responseData.email,
  username: responseData.username,
  roles: responseData.roles,
  institutions: responseData.institutions,
  departments: responseData.departments,
  departmentSections: responseData.department_sections,
});

export interface UsersResponse {
  users: User[];
  totalCount: number;
}

export const usersResponseFromResponse = (
  responseData: any,
): UsersResponse => ({
  users: (responseData.users ?? []).map((user: any) => userFromResponse(user)),
  totalCount: responseData.total_count,
});
