import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { cloneDeep, isEmpty, isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useRecoilValue } from 'recoil';
import Holidays, { HolidaysTypes } from 'date-holidays';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { Department } from '../../interfaces/Department';
import {
  AlgorithmParameters,
  Schedule,
  SchedulePersonnel,
  schedulePersonnelFromPersonnel,
  ScheduleSummary,
} from '../../interfaces/Schedule';
import { insertSchedule } from '../../services/Schedule';
import {
  formatLocalTime,
  getDatePickerLocale,
  getFirstDayOfCurrentMonth,
  getLastDayOfMonth,
  removeTimezone,
} from '../../utils/Date';
import { i18StringFromPosition } from '../../utils/Personnel';
import { PersonnelPosition } from '../../interfaces/Personnel';
import NumberInput from '../NumberInput';
import { tokenDataAtom } from '../../atoms/TokenData';
import { UserRole } from '../../interfaces/User';
import AlgorithmParametersInput from './AlgorithmParametersInput';
import { defaultAlgorithmParameters } from '../../variables/Schedule';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import DepartmentSectionAutocomplete from '../DepartmentSection/DepartmentSectionAutocomplete';
import ConfirmDialog from '../ConfirmDialog';
import { FullDayDates } from '../../interfaces/FullDayDates';
import { getRawDepartmentSettings } from '../../utils/Department';
import { Institution } from '../../interfaces/Institution';
import InstitutionDropdown from '../Institution/InstitutionDropdown';
import { PtoSubtype } from '../../interfaces/PtoSubtype';
import PtoSubtypeDropdown from './PtoSubtypeDropdown';
import { RotationType } from '../../utils/enums/RotationType';
import AdvancedSettingsDialog from './AdvancedSettingsDialog';
import {
  AlgorithmParameterMultipliers,
  getDefaultAlgorithmParameterMultipliers,
} from '../../interfaces/AlgorithmParameterMultipliers';

interface InsertScheduleDialogProps {
  open: boolean;
  institutions: Institution[];
  departments: Department[];
  schedules: Schedule[];
  scheduleSummaries: ScheduleSummary[];
  onClose: () => void;
  onUpsert: () => void;
}

interface InsertScheduleDialogState {
  institutionID: number;
  department: Department;
  departmentSection?: DepartmentSection;
  startAt: Date;
  endAt: Date;
  name: string;
  personnels: SchedulePersonnel[];
  selectedPersonnelIDs: number[];
  maxOvertimeHours: number;
  previousScheduleID?: number;
  previousScheduleName?: string;
  algorithmParameters: AlgorithmParameters;
  algorithmParameterMultipliers: AlgorithmParameterMultipliers;
  fullDayDates: FullDayDates[];
  weekdayRotationType: RotationType;
  weekendRotationType: RotationType;
  administrativeHours: number;
  selectedFullDaysIndex?: number;
}

interface IntersectingDayIndices {
  personnelIndex: number;
  rangeIndex: number;
}

const InsertScheduleDialog = (props: InsertScheduleDialogProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const hd = new Holidays('TR');

  const getHolidaysBetweenDates = (
    startAt: Date,
    endAt: Date,
    department: Department,
    personnels: SchedulePersonnel[],
    selectedPersonnelIDs: number[],
  ): FullDayDates[] => {
    if (startAt > endAt) {
      return [];
    }

    let startYear = startAt.getFullYear();
    const endYear = endAt.getFullYear();
    let allHolidays: HolidaysTypes.Holiday[] = [];
    const filteredHolidays: FullDayDates[] = [];

    if (startYear === endYear) {
      allHolidays = hd.getHolidays(startYear);
    } else {
      while (startYear === endYear) {
        allHolidays.push(...hd.getHolidays(startYear));
        startYear += 1;
      }
    }

    allHolidays.forEach((holiday) => {
      if (holiday.start <= endAt && holiday.end > startAt) {
        holiday.start.setHours(0);
        holiday.end.setHours(0);
        holiday.start.setMinutes(0);
        holiday.end.setMinutes(0);
        holiday.end.setDate(holiday.end.getDate() - 1); // HolidaysTypes.Holiday is endAt exclusive
        filteredHolidays.push({
          startAt: holiday.start < startAt ? startAt : holiday.start,
          endAt: holiday.end < endAt ? holiday.end : endAt,
          rotationType: RotationType.FULL_DAY,
          name: holiday.name,
          isOfficialHoliday: true,
          minimumSeniorPersonnelCount:
            department.minimumSeniorPersonnelCounts.weekend,
          minimumPersonnelCount: department.minimumPersonnelCounts.weekend,
          availablePersonnelIds: personnels
            .filter(
              (personnel: SchedulePersonnel) =>
                selectedPersonnelIDs.includes(personnel.ID) &&
                personnel.position !== PersonnelPosition.HEAD_NURSE,
            )
            .map((p) => p.ID),
        });
      }
    });
    return filteredHolidays;
  };
  const getDefaultState = (): InsertScheduleDialogState => {
    const department = props.institutions[0].departments[0];
    const personnels = (department.departmentSections[0]?.personnels ?? []).map(
      (p) => schedulePersonnelFromPersonnel(p),
    );
    const selectedPersonnelIDs = (
      department.departmentSections[0]?.personnels ?? []
    ).map((p) => p.ID!);

    return {
      institutionID: props.institutions[0]?.ID ?? 1,
      department,
      departmentSection: department.departmentSections[0],
      startAt: getFirstDayOfCurrentMonth(),
      endAt: getLastDayOfMonth(),
      name: '',
      maxOvertimeHours: 130,
      personnels,
      selectedPersonnelIDs,
      algorithmParameters: defaultAlgorithmParameters,
      administrativeHours: 0,
      algorithmParameterMultipliers: getDefaultAlgorithmParameterMultipliers(),
      fullDayDates: getHolidaysBetweenDates(
        getFirstDayOfCurrentMonth(),
        getLastDayOfMonth(),
        department,
        personnels,
        selectedPersonnelIDs,
      ),
      weekdayRotationType: RotationType.FULL_DAY,
      weekendRotationType: RotationType.FULL_DAY,
    };
  };

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState<InsertScheduleDialogState>(
    getDefaultState(),
  );
  const [intersectingDayIndices, setIntersectingDayIndices] = useState<
    IntersectingDayIndices[]
  >([]);
  const [intersectingFullDayIndices, setIntersectingFullDayIndices] = useState<
    number[]
  >([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    const newIntersectingDayIndices: IntersectingDayIndices[] = [];
    for (let i = 0; i < currentState.personnels.length; i += 1) {
      const personnel = currentState.personnels[i];
      for (let j = 0; j < personnel.unavailableDays.length - 1; j += 1) {
        for (let k = j + 1; k < personnel.unavailableDays.length; k += 1) {
          const range1 = personnel.unavailableDays[j];
          const range2 = personnel.unavailableDays[k];

          if (
            range1.startAt <= range2.endAt &&
            range1.endAt >= range2.startAt
          ) {
            newIntersectingDayIndices.push({
              personnelIndex: i,
              rangeIndex: j,
            });
            newIntersectingDayIndices.push({
              personnelIndex: i,
              rangeIndex: k,
            });
          }
        }
      }
    }

    setIntersectingDayIndices(newIntersectingDayIndices);
  }, [currentState.personnels]);

  useEffect(() => {
    const newIntersectingDayIndices: number[] = [];

    for (let j = 0; j < currentState.fullDayDates.length - 1; j += 1) {
      for (let k = j + 1; k < currentState.fullDayDates.length; k += 1) {
        const range1 = currentState.fullDayDates[j];
        const range2 = currentState.fullDayDates[k];

        if (
          removeTimezone(range1.startAt) <= removeTimezone(range2.endAt) &&
          removeTimezone(range1.endAt) >= removeTimezone(range2.startAt)
        ) {
          newIntersectingDayIndices.push(j, k);
        }
      }
    }

    setIntersectingFullDayIndices(newIntersectingDayIndices);
  }, [currentState.fullDayDates]);

  useEffect(() => {
    setCurrentState({
      ...currentState,
      fullDayDates: getHolidaysBetweenDates(
        currentState.startAt,
        currentState.endAt,
        currentState.department,
        currentState.personnels,
        currentState.selectedPersonnelIDs,
      ),
    });
  }, [currentState.startAt, currentState.endAt]);

  useEffect(() => {
    let previousScheduleID: number | undefined;
    let previousScheduleName: string | undefined;

    if (isFinite(currentState.previousScheduleID)) {
      const isSameSection: boolean =
        props.scheduleSummaries.find(
          (schedule) => schedule.ID === currentState.previousScheduleID,
        )?.departmentSectionID === currentState.departmentSection?.ID &&
        isFinite(currentState.departmentSection?.ID);

      previousScheduleID = isSameSection
        ? currentState.previousScheduleID
        : undefined;

      previousScheduleName = isSameSection
        ? currentState.previousScheduleName
        : undefined;
    }

    const selectedPersonnelIDs = (
      currentState.departmentSection?.personnels ?? []
    ).map((p) => p.ID!);

    const selectedPersonnelIDsWithoutHeadNurse = (
      currentState.departmentSection?.personnels ?? []
    )
      .filter((p) => p.position !== PersonnelPosition.HEAD_NURSE)
      .map((p) => p.ID!);

    const fullDayDates = cloneDeep(currentState.fullDayDates);

    for (let i = 0; i < fullDayDates.length; i++) {
      fullDayDates[i].availablePersonnelIds =
        selectedPersonnelIDsWithoutHeadNurse;
      fullDayDates[i].minimumPersonnelCount =
        currentState.department.minimumPersonnelCounts.weekend;
      fullDayDates[i].minimumSeniorPersonnelCount =
        currentState.department.minimumSeniorPersonnelCounts.weekend;
    }

    setCurrentState({
      ...currentState,
      personnels: (currentState.departmentSection?.personnels ?? []).map(
        (p) => {
          const personnel = schedulePersonnelFromPersonnel(p);
          const existingPersonnel = currentState.personnels.find(
            (x) => isFinite(x.ID) && x.ID === personnel.ID,
          );

          if (existingPersonnel) {
            personnel.workAtAllWorkdayDayShifts =
              existingPersonnel.workAtAllWorkdayDayShifts;
            personnel.unavailableDays = cloneDeep(
              existingPersonnel.unavailableDays,
            );
          }

          return personnel;
        },
      ),
      selectedPersonnelIDs,
      fullDayDates,
      previousScheduleID,
      previousScheduleName,
    });
  }, [currentState.departmentSection]);

  useEffect(() => {
    setCurrentState(getDefaultState());
  }, [props.open]);

  const handleConfirm = () => {
    if (showConfirmDialog) {
      setShowConfirmDialog(false);
    }

    setLoading(true);

    const filteredPersonnels = currentState.personnels.filter(
      (personnel: SchedulePersonnel) =>
        currentState.selectedPersonnelIDs.includes(personnel.ID),
    );

    localStorage.setItem('lastSchedule', JSON.stringify(currentState));

    insertSchedule({
      name: currentState.name,
      startAt: currentState.startAt,
      endAt: currentState.endAt,
      institutionID: currentState.institutionID,
      departmentID: currentState.department.ID!,
      departmentSectionID: currentState.departmentSection!.ID!,
      personnels: filteredPersonnels,
      previousScheduleID: currentState.previousScheduleID,
      previousScheduleName: currentState.previousScheduleName,
      maxOvertimeHours: currentState.maxOvertimeHours,
      algorithmParameters: currentState.algorithmParameters,
      algorithmParameterMultipliers: currentState.algorithmParameterMultipliers,
      fullDayDates: currentState.fullDayDates,
      weekdayRotationType: currentState.weekdayRotationType,
      weekendRotationType: currentState.weekendRotationType,
      administrativeHours: currentState.administrativeHours,
      departmentSettings: getRawDepartmentSettings(currentState.department),
    })
      .then(() => {
        toast.success(t('schedule.successful.add'));
        props.onUpsert();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleClickAdd = () => {
    if (currentState.previousScheduleID === undefined) {
      setShowConfirmDialog(true);
    } else {
      handleConfirm();
    }
  };

  const handleCheckPersonnel = (id: number) => {
    const selectedIndex = currentState.selectedPersonnelIDs.indexOf(id);
    let newSelected: number[] = [];

    const fullDayDates = cloneDeep(currentState.fullDayDates);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(currentState.selectedPersonnelIDs, id);
    } else {
      newSelected = currentState.selectedPersonnelIDs.filter(
        (num) => num !== id,
      );
      for (let i = 0; i < fullDayDates.length; i++) {
        fullDayDates[i].availablePersonnelIds = fullDayDates[
          i
        ].availablePersonnelIds.filter((num) => num !== id);
      }
    }

    setCurrentState({
      ...currentState,
      selectedPersonnelIDs: newSelected,
      fullDayDates,
    });
  };

  const handleDayShiftChange = (id: number) => {
    setCurrentState({
      ...currentState,
      personnels: currentState.personnels.map((p) =>
        p.ID === id
          ? { ...p, workAtAllWorkdayDayShifts: !p.workAtAllWorkdayDayShifts }
          : p,
      ),
    });
  };

  const handleCanBeOnCallChange = (id: number) => {
    setCurrentState({
      ...currentState,
      personnels: currentState.personnels.map((p) =>
        p.ID === id ? { ...p, canBeOnCall: !p.canBeOnCall } : p,
      ),
    });
  };

  const handleChangeWeekendRotationType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentState({
      ...currentState,
      weekendRotationType: (event.target as HTMLInputElement)
        .value as RotationType,
    });
  };

  const handleChangeWeekdayRotationType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentState({
      ...currentState,
      weekdayRotationType: (event.target as HTMLInputElement)
        .value as RotationType,
    });
  };

  const handleUnAvailableDatesStartAtChange = (
    personnelIndex: number,
    rangeIndex: number,
    date: Date | null,
  ) => {
    if (!date) {
      return;
    }

    const newPersonnels = cloneDeep(currentState.personnels);
    if (newPersonnels[personnelIndex].unavailableDays.length <= rangeIndex) {
      newPersonnels[personnelIndex].unavailableDays = [
        ...newPersonnels[personnelIndex].unavailableDays,
        {
          startAt: date,
          endAt: date,
          ptoSubtype: PtoSubtype.ANNUAL,
        },
      ];
    } else {
      newPersonnels[personnelIndex].unavailableDays[rangeIndex].startAt = date;
    }

    setCurrentState({
      ...currentState,
      personnels: newPersonnels,
    });
  };

  const handleChangeFullDayDates = (index: number, e: any) => {
    const fullDayDates = cloneDeep(currentState.fullDayDates);

    fullDayDates[index].name = e.target.value;

    setCurrentState({ ...currentState, fullDayDates });
  };

  const handleChangeFullDaySeniorPersonnelCount = (index: number, e: any) => {
    const fullDayDates = cloneDeep(currentState.fullDayDates);

    fullDayDates[index].minimumSeniorPersonnelCount = parseInt(
      e.target.value,
      10,
    );

    setCurrentState({ ...currentState, fullDayDates });
  };

  const handleChangeFullDayPersonnelChange = (e: any) => {
    const fullDayDates = cloneDeep(currentState.fullDayDates);
    fullDayDates[currentState.selectedFullDaysIndex!].availablePersonnelIds =
      e.target.value;
    setCurrentState({ ...currentState, fullDayDates });
  };

  const handleChangeFullDayPersonnelCount = (index: number, e: any) => {
    const fullDayDates = cloneDeep(currentState.fullDayDates);

    fullDayDates[index].minimumPersonnelCount = parseInt(e.target.value, 10);

    setCurrentState({ ...currentState, fullDayDates });
  };

  const handleChangeFullDayIsOfficial = (index: number) => {
    const fullDayDates = cloneDeep(currentState.fullDayDates);

    fullDayDates[index].isOfficialHoliday =
      !fullDayDates[index].isOfficialHoliday;

    setCurrentState({ ...currentState, fullDayDates });
  };

  const handleChangeFullDayRotationTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const fullDayDates = cloneDeep(currentState.fullDayDates);

    fullDayDates[index].rotationType = (event.target as HTMLInputElement)
      .value as RotationType;

    setCurrentState({ ...currentState, fullDayDates });
  };

  const handleFullDayDatesChange = (
    index: number,
    date: Date | null,
    isStartAt: boolean,
  ) => {
    if (!date) {
      return;
    }

    const fullDayDates = cloneDeep(currentState.fullDayDates);
    if (fullDayDates.length <= index) {
      fullDayDates.push({
        startAt: date,
        endAt: date,
        name: '',
        isOfficialHoliday: false,
        rotationType: RotationType.FULL_DAY,
        minimumPersonnelCount:
          currentState.department.minimumPersonnelCounts.weekend,
        minimumSeniorPersonnelCount:
          currentState.department.minimumSeniorPersonnelCounts.weekend,
        availablePersonnelIds: currentState.personnels
          .filter(
            (personnel: SchedulePersonnel) =>
              currentState.selectedPersonnelIDs.includes(personnel.ID) &&
              personnel.position !== PersonnelPosition.HEAD_NURSE,
          )
          .map((p) => p.ID),
      });
    } else if (isStartAt) {
      fullDayDates[index].startAt = date;
    } else {
      fullDayDates[index].endAt = date;
    }

    setCurrentState({
      ...currentState,
      fullDayDates,
    });
  };

  const handleUnAvailableDatesEndAtChange = (
    personnelIndex: number,
    rangeIndex: number,
    date: Date | null,
  ) => {
    if (!date) {
      return;
    }

    const newPersonnels = cloneDeep(currentState.personnels);
    if (newPersonnels[personnelIndex].unavailableDays.length <= rangeIndex) {
      newPersonnels[personnelIndex].unavailableDays = [
        ...newPersonnels[personnelIndex].unavailableDays,
        {
          startAt: date,
          endAt: date,
          ptoSubtype: PtoSubtype.ANNUAL,
        },
      ];
    } else {
      newPersonnels[personnelIndex].unavailableDays[rangeIndex].endAt = date;
    }

    setCurrentState({
      ...currentState,
      personnels: newPersonnels,
    });
  };

  const handleChangePtoSubtype = (
    personnelIndex: number,
    rangeIndex: number,
    ptoSubtype: PtoSubtype,
  ) => {
    const newPersonnels = cloneDeep(currentState.personnels);
    if (newPersonnels[personnelIndex].unavailableDays.length > rangeIndex) {
      newPersonnels[personnelIndex].unavailableDays[rangeIndex].ptoSubtype =
        ptoSubtype;
    }

    setCurrentState({
      ...currentState,
      personnels: newPersonnels,
    });
  };

  const handleDeleteUnavailableDateRange = (
    personnelIndex: number,
    rangeIndex: number,
  ) => {
    const newPersonnels = cloneDeep(currentState.personnels);
    newPersonnels[personnelIndex].unavailableDays = [
      ...newPersonnels[personnelIndex].unavailableDays.slice(0, rangeIndex),
      ...newPersonnels[personnelIndex].unavailableDays.slice(rangeIndex + 1),
    ];

    setCurrentState({
      ...currentState,
      personnels: newPersonnels,
    });
  };

  const handleDeleteFullDayDates = (index: number) => {
    const fullDayDates = currentState.fullDayDates.filter(
      (_, i) => i !== index,
    );

    setCurrentState({
      ...currentState,
      fullDayDates,
    });
  };

  const handleChangeName = (e: any) => {
    setCurrentState({ ...currentState, name: e.target.value });
  };

  const handleChangeMaxOvertimeHours = (newValue: number) => {
    setCurrentState({ ...currentState, maxOvertimeHours: newValue });
  };

  const handleChangeAdministrativeHours = (newValue: number) => {
    setCurrentState({ ...currentState, administrativeHours: newValue });
  };

  const handleChangeInstitution = (newInstitution: Institution) => {
    setCurrentState({
      ...currentState,
      institutionID: newInstitution.ID!,
      department: newInstitution.departments[0],
      departmentSection: newInstitution.departments[0].departmentSections[0],
    });
  };

  const handleChangeDepartment = (e: any) => {
    const department = props.departments.find(
      (x) => x.name === e.target.value,
    )!;

    setCurrentState({
      ...currentState,
      department,
      departmentSection: department.departmentSections[0],
    });
  };

  const handleChangeDepartmentSection = (
    newDepartmentSection: DepartmentSection | null,
  ) => {
    setCurrentState({
      ...currentState,
      departmentSection: newDepartmentSection ?? undefined,
    });
  };

  const handleChangePreviousSchedule = (e: any) => {
    const newID: number = e.target.value;

    setCurrentState({
      ...currentState,
      previousScheduleID: newID < 0 || !isFinite(newID) ? undefined : newID,
      previousScheduleName:
        newID < 0 || !isFinite(newID)
          ? undefined
          : props.scheduleSummaries.find((sum) => sum.ID === newID)!.name,
    });
  };

  const handleChangeAlgorithmParameters = (
    newParameters: AlgorithmParameters,
  ) => {
    setCurrentState({ ...currentState, algorithmParameters: newParameters });
  };

  const handleRestore = () => {
    const lastScheduleAsStr = localStorage.getItem('lastSchedule');
    if (!lastScheduleAsStr) {
      return;
    }

    const lastSchedule = JSON.parse(lastScheduleAsStr);
    setCurrentState({
      ...lastSchedule,
      startAt: new Date(lastSchedule.startAt),
      endAt: new Date(lastSchedule.endAt),
      personnels: lastSchedule.personnels.map(
        (personnel: SchedulePersonnel) => ({
          ...personnel,
          unavailableDays: personnel.unavailableDays.map((range) => ({
            startAt: new Date(range.startAt),
            endAt: new Date(range.endAt),
            ptoSubtype: range.ptoSubtype,
          })),
        }),
      ),
      fullDayDates: (lastSchedule.fullDayDates ?? []).map((dates: any) => ({
        ...dates,
        startAt: new Date(dates.startAt),
        endAt: new Date(dates.endAt),
      })),
      algorithmParameters: {
        ...defaultAlgorithmParameters,
        ...lastSchedule.algorithmParameters,
      },
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false}>
      <Waiting open={loading} />
      <ConfirmDialog
        content={t('schedule.confirm_without_previous_schedule_message')}
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirm}
        color='primary'
      />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {t('schedule.dialog.add')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid
          container
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', mt: 1.5 }}
        >
          <Grid>
            <TextField
              label={t('schedule.name')}
              value={currentState.name}
              onChange={handleChangeName}
              size='small'
              error={isEmpty(currentState.name)}
              sx={{ width: 200, mr: 1.5 }}
            />
          </Grid>
          <Grid sx={{ mr: 1.5 }}>
            <InstitutionDropdown
              label={t('schedule.institution') as string}
              width={200}
              required
              options={props.institutions}
              value={currentState.institutionID}
              onChange={handleChangeInstitution}
            />
          </Grid>
          <Grid>
            <FormControl size='small' sx={{ minWidth: 200 }}>
              <InputLabel>{t('schedule.department')}</InputLabel>
              <Select
                value={
                  currentState.department ? currentState.department.name : ''
                }
                label={t('schedule.department')}
                onChange={handleChangeDepartment}
                name='select'
                required
              >
                {props.departments
                  .filter(
                    (department) =>
                      department.institutionID === currentState.institutionID,
                  )
                  .map((department) => (
                    <MenuItem value={department.name} key={department.ID}>
                      {department.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ pl: 1.5 }}>
            <DepartmentSectionAutocomplete
              label={t('schedule.department_section') as string}
              options={currentState.department?.departmentSections ?? []}
              value={currentState.departmentSection ?? null}
              disabled={!currentState.department}
              onChange={handleChangeDepartmentSection}
              width={200}
              backgroundColor={sharedColors.white}
            />
          </Grid>
        </Grid>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={getDatePickerLocale()}
        >
          <Box
            component='div'
            sx={{ display: 'flex', flexDirection: 'row', mt: 1.5 }}
          >
            <DatePicker
              label={t('shared.start_at')}
              value={currentState.startAt}
              inputFormat='d MMMM yyyy'
              onChange={(newValue: Date | null) =>
                setCurrentState({
                  ...currentState,
                  startAt: newValue ?? getFirstDayOfCurrentMonth(),
                  endAt: newValue
                    ? getLastDayOfMonth(newValue)
                    : getLastDayOfMonth(),
                })
              }
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => (
                <TextField
                  {...params}
                  size='small'
                  sx={{
                    width: 200,
                  }}
                />
              )}
            />
            <Box component='div' sx={{ mr: 1.5 }} />
            <DatePicker
              label={t('shared.end_at')}
              value={currentState.endAt}
              inputFormat='d MMMM yyyy'
              onChange={(newValue: Date | null) =>
                setCurrentState({
                  ...currentState,
                  endAt: newValue ?? getLastDayOfMonth(),
                })
              }
              renderInput={(
                params: JSX.IntrinsicAttributes & TextFieldProps,
              ) => (
                <TextField
                  {...params}
                  size='small'
                  sx={{
                    width: 200,
                    mr: 1.5,
                  }}
                />
              )}
            />
            <Box component='div' sx={{ width: 200, mr: 1.5 }}>
              <NumberInput
                value={currentState.maxOvertimeHours}
                label={t('schedule.max_overtime_hours') as string}
                onChange={handleChangeMaxOvertimeHours}
                error={currentState.maxOvertimeHours < 0}
              />
            </Box>
            <Box component='div' sx={{ width: 200 }}>
              <NumberInput
                value={currentState.administrativeHours}
                label={t('schedule.administrative_hours') as string}
                onChange={handleChangeAdministrativeHours}
                acceptFloat
                precision={2}
                endAdornment={
                  <InputAdornment position='end'>
                    {t('shared.hours')}
                  </InputAdornment>
                }
                error={currentState.administrativeHours < 0}
              />
            </Box>
          </Box>
        </LocalizationProvider>
        <Grid
          container
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', mt: 1.5 }}
        >
          <Grid>
            <FormControl size='small' sx={{ width: 250 }}>
              <InputLabel>{t('schedule.previous_schedule')}</InputLabel>
              <Select
                value={currentState.previousScheduleID ?? -1}
                label={t('schedule.previous_schedule')}
                onChange={handleChangePreviousSchedule}
                name='select'
              >
                <MenuItem value={-1}>-</MenuItem>
                {props.scheduleSummaries
                  .filter(
                    (schedule) =>
                      schedule.departmentSectionID ===
                      currentState.departmentSection?.ID,
                  )
                  .map((schedule) => (
                    <MenuItem value={schedule.ID} key={schedule.ID}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>{schedule.name}</Typography>
                        {currentState.previousScheduleID !== schedule.ID && (
                          <Typography
                            sx={{
                              ...sharedStyles.subtitle2,
                              color: sharedColors.gray4,
                            }}
                          >
                            {t('schedule.previous_schedule_subtitle', {
                              date: formatLocalTime(
                                t,
                                schedule.audit.lastUpdatedAt ??
                                  schedule.audit.createdAt,
                              ),
                            })}
                          </Typography>
                        )}
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ pl: 2.5, mt: -0.6 }}>
            <FormControl>
              <FormLabel sx={sharedStyles.body2}>
                {t('schedule.weekday_rotation_type')}
              </FormLabel>
              <RadioGroup
                row
                value={currentState.weekdayRotationType}
                onChange={handleChangeWeekdayRotationType}
              >
                <FormControlLabel
                  value={RotationType.FULL_DAY}
                  control={<Radio size='small' />}
                  label={t('schedule.all_day_rotation')}
                />
                <FormControlLabel
                  value={RotationType.DAY_NIGHT}
                  control={<Radio size='small' />}
                  label={t('schedule.day_night_rotation')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid sx={{ pl: 2.5, mt: -0.6 }}>
            <FormControl>
              <FormLabel sx={sharedStyles.body2}>
                {t('schedule.weekend_rotation_type')}
              </FormLabel>
              <RadioGroup
                row
                value={currentState.weekendRotationType}
                onChange={handleChangeWeekendRotationType}
              >
                <FormControlLabel
                  value={RotationType.FULL_DAY}
                  control={<Radio size='small' />}
                  label={t('schedule.all_day_rotation')}
                />
                <FormControlLabel
                  value={RotationType.DAY_NIGHT}
                  control={<Radio size='small' />}
                  label={t('schedule.day_night_rotation')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid sx={{ pl: 2.5 }}>
            <AdvancedSettingsDialog
              algorithmParameterMultipliers={
                currentState.algorithmParameterMultipliers
              }
              onChange={(multipliers: AlgorithmParameterMultipliers) => {
                setCurrentState({
                  ...currentState,
                  algorithmParameterMultipliers: multipliers,
                });
              }}
            />
          </Grid>
        </Grid>
        <Box
          component='div'
          sx={{
            display: 'inline-block',
            paddingTop: 1,
          }}
        >
          <Typography sx={sharedStyles.h7}>
            {t('schedule.all_day_dates')}
          </Typography>
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'column', mt: 1.5 }}
        >
          {Array.from(
            { length: currentState.fullDayDates.length + 1 },
            (_, j) => j,
          ).map((j) => (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={getDatePickerLocale()}
            >
              <Box
                component='div'
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: j ? 1.5 : -1,
                }}
              >
                <DatePicker
                  label={t('shared.start_at')}
                  value={
                    currentState.fullDayDates[j]
                      ? currentState.fullDayDates[j].startAt
                      : null
                  }
                  inputFormat='d MMMM yyyy'
                  minDate={currentState.startAt}
                  maxDate={
                    currentState.fullDayDates[j]
                      ? currentState.fullDayDates[j].endAt ?? currentState.endAt
                      : currentState.endAt
                  }
                  onChange={(newValue: Date | null) =>
                    handleFullDayDatesChange(j, newValue, true)
                  }
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps,
                  ) => (
                    <TextField
                      {...params}
                      error={!!intersectingFullDayIndices.find((i) => i === j)}
                      size='small'
                      onKeyDown={(e) => e.preventDefault()}
                      sx={{
                        caretColor: 'transparent',
                        '& input': {
                          cursor: 'pointer',
                        },
                        maxWidth: 200,
                        mt: 'auto',
                        mb: 'auto',
                      }}
                    />
                  )}
                />
                <Box component='div' sx={{ mr: 1 }} />
                <DatePicker
                  label={t('shared.end_at')}
                  value={
                    currentState.fullDayDates[j]
                      ? currentState.fullDayDates[j].endAt
                      : null
                  }
                  inputFormat='d MMMM yyyy'
                  minDate={
                    currentState.fullDayDates[j]
                      ? currentState.fullDayDates[j].startAt ??
                        currentState.startAt
                      : currentState.startAt
                  }
                  maxDate={currentState.endAt}
                  onChange={(newValue: Date | null) =>
                    handleFullDayDatesChange(j, newValue, false)
                  }
                  renderInput={(
                    params: JSX.IntrinsicAttributes & TextFieldProps,
                  ) => (
                    <TextField
                      {...params}
                      error={!!intersectingFullDayIndices.find((i) => i === j)}
                      size='small'
                      onKeyDown={(e) => e.preventDefault()}
                      sx={{
                        caretColor: 'transparent',
                        '& input': {
                          cursor: 'pointer',
                        },
                        maxWidth: 200,
                        mt: 'auto',
                        mb: 'auto',
                      }}
                    />
                  )}
                />
                <TextField
                  label={t('schedule.name')}
                  value={
                    currentState.fullDayDates[j]
                      ? currentState.fullDayDates[j].name
                      : ''
                  }
                  onChange={(e) => handleChangeFullDayDates(j, e)}
                  size='small'
                  disabled={!currentState.fullDayDates[j]}
                  sx={{
                    width: 200,
                    mr: 1.5,
                    paddingLeft: 1,
                    mt: 'auto',
                    mb: 'auto',
                  }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      disabled={!currentState.fullDayDates[j]}
                      checked={
                        currentState.fullDayDates[j]
                          ? currentState.fullDayDates[j].isOfficialHoliday
                          : false
                      }
                      onChange={() => handleChangeFullDayIsOfficial(j)}
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={
                    <Typography sx={{ ...sharedStyles.subtitle2 }}>
                      {t('schedule.is_official_holiday')}
                    </Typography>
                  }
                />
                <FormControl>
                  <FormLabel sx={sharedStyles.body2}>
                    {t('schedule.rotation_type')}
                  </FormLabel>
                  <RadioGroup
                    row
                    value={
                      currentState.fullDayDates[j]
                        ? currentState.fullDayDates[j].rotationType
                        : ''
                    }
                    onChange={(e) =>
                      handleChangeFullDayRotationTypeChange(e, j)
                    }
                  >
                    <FormControlLabel
                      disabled={!currentState.fullDayDates[j]}
                      value={RotationType.FULL_DAY}
                      control={<Radio size='small' />}
                      label={t('schedule.all_day_rotation')}
                    />
                    <FormControlLabel
                      disabled={!currentState.fullDayDates[j]}
                      value={RotationType.DAY_NIGHT}
                      control={<Radio size='small' />}
                      label={t('schedule.day_night_rotation')}
                    />
                  </RadioGroup>
                </FormControl>
                <React.Fragment>
                  <Button
                    variant='outlined'
                    sx={{ textTransform: 'none' }}
                    onClick={() =>
                      setCurrentState({
                        ...currentState,
                        selectedFullDaysIndex: j,
                      })
                    }
                    disabled={!currentState.fullDayDates[j]}
                    size={'small'}
                  >
                    {t('schedule.personnel_constraints')}
                  </Button>
                  <Dialog
                    open={currentState.selectedFullDaysIndex !== undefined}
                    onClose={() =>
                      setCurrentState({
                        ...currentState,
                        selectedFullDaysIndex: undefined,
                      })
                    }
                    PaperProps={{
                      style: {
                        width: 450,
                        height: 350,
                      },
                    }}
                  >
                    <DialogTitle fontSize={'medium'}>
                      {currentState.selectedFullDaysIndex !== undefined
                        ? `${
                            currentState.fullDayDates[
                              currentState.selectedFullDaysIndex
                            ].name
                          } ${t('schedule.personnel_constraints')}`
                        : t('schedule.personnel_constraints')}
                    </DialogTitle>
                    <DialogContent
                      sx={{ display: 'flex', flexDirection: 'column' }}
                      dividers
                    >
                      <TextField
                        label={t('department.min_senior_personnel_count')}
                        type='number'
                        value={
                          currentState.selectedFullDaysIndex !== undefined
                            ? currentState.fullDayDates[
                                currentState.selectedFullDaysIndex
                              ].minimumSeniorPersonnelCount
                            : 0
                        }
                        onChange={(e) =>
                          handleChangeFullDaySeniorPersonnelCount(
                            currentState.selectedFullDaysIndex!,
                            e,
                          )
                        }
                        size='small'
                        sx={{ paddingBottom: 2 }}
                      />
                      <TextField
                        label={t('department.min_personnel_count')}
                        type='number'
                        value={
                          currentState.selectedFullDaysIndex !== undefined
                            ? currentState.fullDayDates[
                                currentState.selectedFullDaysIndex
                              ].minimumPersonnelCount
                            : 0
                        }
                        onChange={(e) =>
                          handleChangeFullDayPersonnelCount(
                            currentState.selectedFullDaysIndex!,
                            e,
                          )
                        }
                        size='small'
                        sx={{ paddingBottom: 2 }}
                      />
                      <FormControl sx={{ paddingBottom: 2 }}>
                        <InputLabel>
                          {t('schedule.available_personnels')}
                        </InputLabel>
                        <Select
                          multiple
                          renderValue={(selected) =>
                            `${t('schedule.stats.personnel_count')}:${
                              selected.length
                            }`
                          }
                          value={
                            currentState.selectedFullDaysIndex !== undefined
                              ? currentState.fullDayDates[
                                  currentState.selectedFullDaysIndex!
                                ].availablePersonnelIds
                              : []
                          }
                          onChange={handleChangeFullDayPersonnelChange}
                          input={
                            <OutlinedInput
                              label={t('schedule.available_personnels')}
                            />
                          }
                        >
                          {currentState.personnels
                            .filter(
                              (personnel: SchedulePersonnel) =>
                                currentState.selectedPersonnelIDs.includes(
                                  personnel.ID,
                                ) &&
                                personnel.position !==
                                  PersonnelPosition.HEAD_NURSE,
                            )
                            .map((personnel) => (
                              <MenuItem value={personnel.ID}>
                                <Checkbox
                                  checked={
                                    currentState.selectedFullDaysIndex !==
                                    undefined
                                      ? currentState.fullDayDates[
                                          currentState.selectedFullDaysIndex!
                                        ].availablePersonnelIds.includes(
                                          personnel.ID,
                                        )
                                      : false
                                  }
                                />
                                <ListItemText primary={personnel.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() =>
                          setCurrentState({
                            ...currentState,
                            selectedFullDaysIndex: undefined,
                          })
                        }
                      >
                        {t('shared.finalize')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
                {currentState.fullDayDates[j] && (
                  <IconButton
                    color='error'
                    onClick={() => handleDeleteFullDayDates(j)}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Box>
            </LocalizationProvider>
          ))}
        </Box>
        <TableContainer
          component={Paper}
          sx={{ ...sharedStyles.tableContainer, minHeight: 450 }}
        >
          <Table size='small'>
            <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
              <TableRow>
                <TableCell>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('shared.active')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('personnel.name')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('personnel.position')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('personnel.preferences')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('personnel.unavailable_days')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentState.personnels.map((row, i) => {
                const isItemSelected =
                  currentState.selectedPersonnelIDs.includes(row.ID);
                const isHeadNurse =
                  row.position === PersonnelPosition.HEAD_NURSE;

                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    aria-disabled={isHeadNurse}
                    selected={row.workAtAllWorkdayDayShifts}
                    key={row.ID}
                  >
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        disabled={isHeadNurse}
                        onClick={() => handleCheckPersonnel(row.ID)}
                        sx={{ p: 0, mt: 0.4 }}
                      />
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Typography sx={sharedStyles.tableStringField}>
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Typography sx={sharedStyles.tableStringField}>
                        {i18StringFromPosition(row.position)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Box
                        component='div'
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <FormControlLabel
                          disabled={!isItemSelected || isHeadNurse}
                          control={
                            <Switch
                              size='small'
                              checked={row.workAtAllWorkdayDayShifts}
                              onChange={() => handleDayShiftChange(row.ID)}
                              color='primary'
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          }
                          label={
                            <Typography sx={{ ...sharedStyles.subtitle2 }}>
                              {t('personnel.works_all_workday_day_shifts')}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          disabled={!isItemSelected || isHeadNurse}
                          control={
                            <Switch
                              size='small'
                              checked={row.canBeOnCall}
                              onChange={() => handleCanBeOnCallChange(row.ID)}
                              color='primary'
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              sx={{
                                '& .MuiSwitch-switchBase:not(.Mui-checked)': {
                                  color: sharedColors.statusRed,
                                },
                                '& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track':
                                  {
                                    backgroundColor: sharedColors.statusRed,
                                  },
                              }}
                            />
                          }
                          label={
                            <Typography sx={{ ...sharedStyles.subtitle2 }}>
                              {t('personnel.can_be_on_call')}
                            </Typography>
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      {Array.from(
                        { length: row.unavailableDays.length + 1 },
                        (_, j) => j,
                      ).map((j) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={getDatePickerLocale()}
                        >
                          <Box
                            component='div'
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              mt: j ? 1.5 : undefined,
                            }}
                          >
                            <DatePicker
                              label={t('shared.start_at')}
                              value={
                                row.unavailableDays[j]
                                  ? row.unavailableDays[j].startAt
                                  : null
                              }
                              inputFormat='d MMMM'
                              minDate={currentState.startAt}
                              maxDate={
                                row.unavailableDays[j]
                                  ? row.unavailableDays[j].endAt ??
                                    currentState.endAt
                                  : currentState.endAt
                              }
                              disabled={!isItemSelected || isHeadNurse}
                              onChange={(newValue: Date | null) =>
                                handleUnAvailableDatesStartAtChange(
                                  i,
                                  j,
                                  newValue,
                                )
                              }
                              renderInput={(
                                params: JSX.IntrinsicAttributes &
                                  TextFieldProps,
                              ) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  error={
                                    !!intersectingDayIndices.find(
                                      (indices) =>
                                        indices.personnelIndex === i &&
                                        indices.rangeIndex === j,
                                    )
                                  }
                                  onKeyDown={(e) => e.preventDefault()}
                                  sx={{
                                    width: '140px',
                                    caretColor: 'transparent',
                                    '& input': {
                                      cursor: 'pointer',
                                    },
                                  }}
                                />
                              )}
                            />
                            <Box component='div' sx={{ mr: 1 }} />
                            <DatePicker
                              label={t('shared.end_at')}
                              value={
                                row.unavailableDays[j]
                                  ? row.unavailableDays[j].endAt
                                  : null
                              }
                              inputFormat='d MMMM'
                              minDate={
                                row.unavailableDays[j]
                                  ? row.unavailableDays[j].startAt ??
                                    currentState.startAt
                                  : currentState.startAt
                              }
                              maxDate={currentState.endAt}
                              disabled={!isItemSelected || isHeadNurse}
                              onChange={(newValue: Date | null) =>
                                handleUnAvailableDatesEndAtChange(
                                  i,
                                  j,
                                  newValue,
                                )
                              }
                              renderInput={(
                                params: JSX.IntrinsicAttributes &
                                  TextFieldProps,
                              ) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  error={
                                    !!intersectingDayIndices.find(
                                      (indices) =>
                                        indices.personnelIndex === i &&
                                        indices.rangeIndex === j,
                                    )
                                  }
                                  onKeyDown={(e) => e.preventDefault()}
                                  sx={{
                                    width: '140px',
                                    caretColor: 'transparent',
                                    '& input': {
                                      cursor: 'pointer',
                                    },
                                  }}
                                />
                              )}
                            />
                            <Box component='div' sx={{ mr: 2 }} />
                            <PtoSubtypeDropdown
                              label={t('pto_subtype.title') as string}
                              width={150}
                              disabled={!row.unavailableDays[j]}
                              value={
                                row.unavailableDays[j]?.ptoSubtype ??
                                PtoSubtype.ANNUAL
                              }
                              onChange={(newPtoSubtype) =>
                                handleChangePtoSubtype(i, j, newPtoSubtype)
                              }
                            />
                            {row.unavailableDays[j] && (
                              <IconButton
                                color='error'
                                onClick={() =>
                                  handleDeleteUnavailableDateRange(i, j)
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </Box>
                        </LocalizationProvider>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {tokenData?.roles?.includes(UserRole.EXPERIMENTAL_ALGORITHM) && [
          <Typography
            sx={{ ...sharedStyles.h5, fontWeight: 'bold', mt: 2.5, mb: 2.5 }}
          >
            {t('schedule.developer_tools')}
          </Typography>,
          <AlgorithmParametersInput
            algorithmParameters={
              currentState.algorithmParameters ?? defaultAlgorithmParameters
            }
            onChange={handleChangeAlgorithmParameters}
          />,
        ]}
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={handleRestore}
          sx={sharedStyles.buttonText}
        >
          {t('shared.restore')}
        </Button>
        <Box component='div' sx={{ flexGrow: 1 }} />
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            isEmpty(currentState.name) ||
            !currentState?.department ||
            !isFinite(currentState.departmentSection?.ID) ||
            !currentState?.startAt ||
            !currentState?.endAt ||
            currentState.startAt > currentState.endAt ||
            !isEmpty(intersectingDayIndices) ||
            !isEmpty(intersectingFullDayIndices) ||
            currentState.maxOvertimeHours < 0 ||
            currentState.administrativeHours < 0
          }
          onClick={handleClickAdd}
          sx={sharedStyles.buttonText}
        >
          {t('schedule.dialog.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsertScheduleDialog;
