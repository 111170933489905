import React, { useEffect } from 'react';
import './App.css';
import { Box } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useKeycloak } from '@react-keycloak/web';
import AppHeader from './components/AppHeader';
import { getAvailablePages } from './variables/Page';
import { appBarHeightAtom } from './atoms/AppHeader';
import SecureComponent from './components/Keycloak/SecureComponent';
import { tokenDataAtom } from './atoms/TokenData';
import { getTokenDataFromKeyCloak } from './utils/Keycloak';

const App = () => {
  const { keycloak, initialized } = useKeycloak();

  const appBarHeight = useRecoilValue(appBarHeightAtom);
  const [tokenData, setTokenData] = useRecoilState(tokenDataAtom);

  useEffect(() => {
    if (keycloak.authenticated) {
      setTokenData(getTokenDataFromKeyCloak(keycloak));
    }
  }, [keycloak, initialized]);

  const pages = getAvailablePages(tokenData);

  const routes = useRoutes([
    {
      path: '/',
      element: <SecureComponent>{pages[0].component}</SecureComponent>,
    },
    {
      path: '',
      element: <SecureComponent>{pages[0].component}</SecureComponent>,
    },
    ...pages.map((page) => ({
      path: `${page.path}/*`,
      element: (
        <SecureComponent>
          {page.disabled ? <></> : page.component}
        </SecureComponent>
      ),
    })),
  ]);

  return (
    <Box component='div'>
      <AppHeader />
      <Box component='div' sx={{ height: appBarHeight }} />
      {routes}
    </Box>
  );
};

export default App;
