import { DepartmentSection } from '../interfaces/DepartmentSection';
import { getStoredLanguage } from './Language';

export const getDepartmentSectionTemplate = (): DepartmentSection => ({
  institutionID: 1,
  institutionName: '',
  departmentID: 0,
  departmentName: '',
  name: '',
  personnels: [],
});

export const compareDepartmentSections = (
  d1: DepartmentSection,
  d2: DepartmentSection,
  fieldName: keyof DepartmentSection,
): number => {
  const locale = getStoredLanguage();

  switch (fieldName) {
    case 'institutionName':
      return d1.institutionName.localeCompare(d2.institutionName, locale);
    case 'departmentID':
      return d1.departmentID - d2.departmentID;
    case 'departmentName':
      return d1.departmentName.localeCompare(d2.departmentName, locale);
    case 'name':
      return d1.name.localeCompare(d2.name, locale);
    case 'personnels':
      return d1.personnels.length - d2.personnels.length;
    default:
      return (d1.ID ?? 0) - (d2.ID ?? 0);
  }
};
