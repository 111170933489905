import { isArray } from 'lodash';
import {
  Personnel,
  personnelFromResponse,
  personnelOutputTransformer,
} from '../interfaces/Personnel';
import api from './Api';

const personnelBaseUrl = '/personnel/';

export const uploadPersonnels = async (
  file: File,
  departmentSectionID: number,
): Promise<void> => {
  const bodyFormData = new FormData();
  bodyFormData.set('file', file, file.name);
  bodyFormData.set('department_section_id', departmentSectionID.toString());

  return api.post(`${personnelBaseUrl}upload`, bodyFormData);
};

export const upsertPersonnel = async (personnel: Personnel): Promise<void> =>
  api.put(personnelBaseUrl, personnelOutputTransformer(personnel));

export const getPersonnels = async (): Promise<Personnel[]> => {
  const response = await api.get(personnelBaseUrl);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((personnel) => personnelFromResponse(personnel));
};

export const deletePersonnel = async (personnelID: number): Promise<void> =>
  api.delete(personnelBaseUrl + personnelID.toString());
