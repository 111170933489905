import { ReactElement } from 'react';
import { flatten, isEmpty } from 'lodash';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { departmentSettingKeys } from '../../variables/Department';

interface DepartmentSettingsTooltipProps {
  departmentSettings: { [key: string]: number };
  children: ReactElement<any, any>;
}

const DepartmentSettingsTooltip = (props: DepartmentSettingsTooltipProps) => {
  const { t } = useTranslation();

  const availableKeys = departmentSettingKeys.filter((key) =>
    Object.keys(props.departmentSettings).includes(key),
  );

  return isEmpty(availableKeys) ? (
    <></>
  ) : (
    <Tooltip
      title={
        <Typography>
          {flatten(
            availableKeys.map((key) => [
              `${t(`department.department_settings.${key}`) 
                }: ${ 
                props.departmentSettings[key].toString()}`,
              <br />,
            ]),
          )}
        </Typography>
      }
    >
      {props.children}
    </Tooltip>
  );
};

export default DepartmentSettingsTooltip;
