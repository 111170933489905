import { isArray } from 'lodash';
import {
  PersonnelOnDuty,
  personnelOnDutyFromResponse,
  PersonnelOnDutyRequest,
  personnelOnDutyRequestOutputTransformer,
} from '../interfaces/PersonnelOnDuty';
import api from './Api';

const personnelOnDutyBaseUrl = '/personnel-on-duty/';

export const getPersonnelsOnDuty = async (
  req: PersonnelOnDutyRequest,
): Promise<PersonnelOnDuty[]> => {
  const response = await api.get(personnelOnDutyBaseUrl, {
    params: personnelOnDutyRequestOutputTransformer(req),
    paramsSerializer: {
      indexes: null,
    },
  });

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((p) => personnelOnDutyFromResponse(p));
};
