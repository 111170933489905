import { isEmpty } from 'lodash';
import {
  Department,
  DepartmentPersonnelCounts,
} from '../interfaces/Department';
import { getStoredLanguage } from './Language';

export const getDepartmentPersonnelCountsTemplate =
  (): DepartmentPersonnelCounts => ({
    day: 0,
    night: 0,
    weekend: 0,
  });

export const getDepartmentTemplate = (): Department => ({
  name: '',
  institutionID: 1,
  institutionName: '',
  minimumPersonnelCounts: getDepartmentPersonnelCountsTemplate(),
  minimumSeniorPersonnelCounts: getDepartmentPersonnelCountsTemplate(),
  totalDepartmentSections: 0,
  totalPersonnels: 0,
  departmentSections: [],
});

export const validateDepartmentPersonnelCounts = (
  departmentPersonnelCounts: DepartmentPersonnelCounts,
): boolean =>
  departmentPersonnelCounts.day >= 0 &&
  departmentPersonnelCounts.night >= 0 &&
  departmentPersonnelCounts.weekend >= 0;

export const getRawDepartmentSettings = (department: Department) => ({
  min_day_shift_personnel_count: department.minimumPersonnelCounts.day,
  min_night_shift_personnel_count: department.minimumPersonnelCounts.night,
  min_weekend_shift_personnel_count: department.minimumPersonnelCounts.weekend,
  min_day_shift_senior_personnel_count:
    department.minimumSeniorPersonnelCounts.day,
  min_night_shift_senior_personnel_count:
    department.minimumSeniorPersonnelCounts.night,
  min_weekend_shift_senior_personnel_count:
    department.minimumSeniorPersonnelCounts.weekend,
});

export const validateDepartment = (department: Department): boolean =>
  !isEmpty(department.name) &&
  validateDepartmentPersonnelCounts(department.minimumPersonnelCounts) &&
  validateDepartmentPersonnelCounts(department.minimumSeniorPersonnelCounts);

export const compareDepartments = (
  d1: Department,
  d2: Department,
  fieldName: keyof Department,
): number => {
  const locale = getStoredLanguage();

  switch (fieldName) {
    case 'institutionName':
      return d1.institutionName.localeCompare(d2.institutionName, locale);
    default:
      return d1.name.localeCompare(d2.name, locale);
  }
};
