import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { sharedStyles } from '../utils/Style';

interface ConfirmDialogProps {
  title?: string;
  content: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  color: 'primary' | 'error';
  children?: JSX.Element[] | JSX.Element;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {props.title ?? t('confirm_dialog.default_title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
        {props.children ?? []}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('confirm_dialog.cancel')}
        </Button>
        <Button
          variant='contained'
          onClick={props.onConfirm}
          autoFocus
          color={props.color}
          sx={sharedStyles.buttonText}
        >
          {t('confirm_dialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
