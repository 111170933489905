import i18next from 'i18next';
import { DateTime } from 'luxon';
import {
  Schedule,
  ScheduleStats,
  ScheduleStatus,
  WorkedHours,
} from '../interfaces/Schedule';
import { getAllDatesBetweenTwoDate, isHoliday } from './Date';
import { getDepartmentTemplate } from './Department';
import { getDepartmentSectionTemplate } from './DepartmentSection';
import { getAuditTemplate } from './Audit';
import { FullDayDates } from '../interfaces/FullDayDates';
import { formatToLimitedDecimals } from './Decimals';
import { Personnel } from '../interfaces/Personnel';
import { getPersonnelPositionWeight } from './Personnel';
import { DateRange } from '../interfaces/DateRange';
import { Shift } from '../interfaces/Shift';
import { RotationType } from './enums/RotationType';
import { occupiedShifts } from '../variables/Schedule';
import { getDefaultAlgorithmParameterMultipliers } from '../interfaces/AlgorithmParameterMultipliers';

export const getScheduleStatsTemplate = (): ScheduleStats => ({
  totalPtoHours: 0,
  totalWorkedHours: 0,
  totalHoursShouldWorked: 0,
  totalOvertimeHours: 0,
});

export const getScheduleTemplate = (): Schedule => ({
  ID: 0,
  institutionID: 1,
  institutionName: '',
  department: getDepartmentTemplate(),
  departmentSection: getDepartmentSectionTemplate(),
  startAt: new Date(),
  endAt: new Date(),
  name: '',
  plan: new Map(),
  overtimeHours: {},
  isModified: false,
  shiftHours: {},
  ptoSubtypes: {},
  shiftChanges: {},
  overtimeChanges: {},
  scheduleNotesCount: 0,
  administrativeHours: 0,
  totalStats: getScheduleStatsTemplate(),
  status: ScheduleStatus.QUEUED,
  audit: getAuditTemplate(),
  fullDayDates: [],
  departmentSettings: {},
  weekendRotationType: RotationType.DAY_NIGHT,
  weekdayRotationType: RotationType.DAY_NIGHT,
  algorithmParameterMultipliers: getDefaultAlgorithmParameterMultipliers(),
});

export const getScheduleDateRange = (schedule: Schedule): DateRange => ({
  startAt: schedule.startAt,
  endAt: schedule.endAt,
});

export const getOvertimeHours = (
  schedule: Schedule,
  personnelName: string,
  dateStr: string,
): number => {
  const personnelOvertimeHours = schedule.overtimeHours[personnelName] ?? {};
  return personnelOvertimeHours[dateStr] ?? 0;
};

export const hoursStringFromShift = (
  schedule: Schedule,
  personnelName?: string,
  dateStr?: string,
  shift?: Shift,
): string => {
  const personnelShiftHours = personnelName
    ? schedule.shiftHours[personnelName]
    : undefined;
  const overtimeHours =
    personnelName && dateStr
      ? getOvertimeHours(schedule, personnelName, dateStr)
      : 0;
  if (personnelShiftHours && shift && personnelShiftHours[shift]) {
    return formatToLimitedDecimals(
      personnelShiftHours[shift] + overtimeHours,
      2,
    );
  }

  switch (shift) {
    case Shift.DAY:
      return formatToLimitedDecimals(8 + overtimeHours, 1);
    case Shift.NIGHT:
      return formatToLimitedDecimals(16 + overtimeHours, 1);
    case Shift.ALL_DAY:
      return formatToLimitedDecimals(24 + overtimeHours, 1);
    case Shift.PTO:
      return i18next.t('schedule.shifts.pto');
    case Shift.ETO:
      return i18next.t('schedule.shifts.eto');
    default:
      return '\u00A0';
  }
};

export const hoursFromShift = (
  schedule: Schedule,
  personnelName: string,
  shift: Shift | undefined,
): number => {
  const personnelShiftHours = schedule.shiftHours[personnelName];
  if (personnelShiftHours && shift && personnelShiftHours[shift]) {
    return personnelShiftHours[shift];
  }

  switch (shift) {
    case Shift.DAY:
      return 8;
    case Shift.NIGHT:
      return 16;
    case Shift.ALL_DAY:
      return 24;
    default:
      return 0;
  }
};

export const getShiftOfPersonnel = (
  schedule: Schedule,
  personnelName: string,
  day: Date,
): Shift => {
  const dateStr = DateTime.fromJSDate(day).toISODate()!;
  // @ts-ignore
  const personnelPlan = schedule.plan[personnelName] ?? {};
  return personnelPlan[dateStr] ?? Shift.EMPTY;
};

export const getWorkedHours = (
  schedule: Schedule,
  personnelName: string,
  shifts: Map<string, Shift>,
  startAt: Date,
  endAt: Date,
  fullDays: FullDayDates[],
): WorkedHours => {
  const workedHours: WorkedHours = {
    totalWorkedHours: schedule.administrativeHours,
    totalHoursShouldWorked: schedule.administrativeHours,
    totalOvertimeHours: 0,
  };

  getAllDatesBetweenTwoDate(startAt, endAt).forEach((date) => {
    if (!isHoliday(date, fullDays)) {
      workedHours.totalHoursShouldWorked += hoursFromShift(
        schedule,
        personnelName,
        Shift.DAY,
      );
    }

    const dateStr = DateTime.fromJSDate(date).toISODate()!;
    // @ts-ignore
    const shift: Shift = shifts[dateStr];
    if (occupiedShifts.includes(shift)) {
      workedHours.totalWorkedHours += getOvertimeHours(
        schedule,
        personnelName,
        dateStr,
      );
    }

    workedHours.totalWorkedHours += hoursFromShift(
      schedule,
      personnelName,
      shift,
    );

    if (shift === Shift.PTO && !isHoliday(date, fullDays)) {
      workedHours.totalHoursShouldWorked -= hoursFromShift(
        schedule,
        personnelName,
        Shift.DAY,
      );
    }
  });

  workedHours.totalOvertimeHours =
    workedHours.totalWorkedHours - workedHours.totalHoursShouldWorked;

  return workedHours;
};

export const getWorkedHoursByDate = (
  schedule: Schedule,
): Map<string, number> => {
  const workedHoursMap: Map<string, number> = new Map();

  const personnelNames = Object.keys(schedule.plan);

  getAllDatesBetweenTwoDate(schedule.startAt, schedule.endAt).forEach(
    (date) => {
      let totalHours: number = 0;

      personnelNames.forEach((personnelName) => {
        // @ts-ignore
        const shiftsOfPersonnel = schedule.plan[personnelName];

        const shift = shiftsOfPersonnel[DateTime.fromJSDate(date).toISODate()!];
        if (shift) {
          totalHours += hoursFromShift(schedule, personnelName, shift);
        }
      });

      // @ts-ignore
      workedHoursMap[DateTime.fromJSDate(date).toISODate()] = totalHours;
    },
  );

  return workedHoursMap;
};

export const compareSchedulePersonnelNames = (
  name1: string,
  name2: string,
  allPersonnels: Personnel[],
): number => {
  const p1 = allPersonnels.find((p) => p.name === name1);
  const p2 = allPersonnels.find((p) => p.name === name2);

  if (!p1) {
    return 1;
  }
  if (!p2) {
    return -1;
  }

  const positionDiff =
    getPersonnelPositionWeight(p2.position) -
    getPersonnelPositionWeight(p1.position);
  if (positionDiff !== 0) {
    return positionDiff;
  }

  return name1.localeCompare(name2);
};
