import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { sharedStyles } from '../../utils/Style';

interface PasswordResetResultDialogProps {
  username?: string;
  newPassword?: string;
  onClose: () => void;
}

const PasswordResetResultDialog = (props: PasswordResetResultDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={!!props.newPassword} onClose={props.onClose}>
      <DialogTitle>{t('user.successful.reset_password_title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('user.successful.reset_password_content', {
            username: props.username ?? '',
          })}
        </DialogContentText>
        <DialogContentText sx={{ fontStyle: 'italic', mt: 1.5 }}>
          {props.newPassword ?? ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordResetResultDialog;
