import { DateTime } from 'luxon';
import { RotationType } from '../utils/enums/RotationType';

export interface FullDayDates {
  startAt: Date;
  endAt: Date;
  name: string;
  isOfficialHoliday: boolean;
  minimumSeniorPersonnelCount: number;
  minimumPersonnelCount: number;
  availablePersonnelIds: number[];
  rotationType: RotationType;
}

export const fullDayDatesOutputTransformer = (fullDays: FullDayDates) => ({
  start_at: DateTime.fromJSDate(fullDays.startAt).toISODate(),
  end_at: DateTime.fromJSDate(fullDays.endAt).toISODate(),
  name: fullDays.name ?? '',
  is_official_holiday: fullDays.isOfficialHoliday,
  minimum_personnel_count: fullDays.minimumPersonnelCount,
  minimum_senior_personnel_count: fullDays.minimumSeniorPersonnelCount,
  available_personnel_ids: fullDays.availablePersonnelIds,
  rotation_type: fullDays.rotationType,
});

export const fullDayDatesFromResponse = (responseData: any): FullDayDates => ({
  startAt: new Date(responseData.start_at),
  endAt: new Date(responseData.end_at),
  name: responseData.name,
  isOfficialHoliday: responseData.is_official_holiday,
  minimumPersonnelCount: responseData.minimum_personnel_count,
  minimumSeniorPersonnelCount: responseData.minimum_senior_personnel_count,
  availablePersonnelIds: responseData.available_personnel_ids,
  rotationType: responseData.rotation_type,
});
