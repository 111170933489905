import { isArray, isFinite } from 'lodash';
import api from './Api';
import {
  Schedule,
  ScheduleGetRequest,
  ScheduleInsertRequest,
  scheduleInsertRequestOutputTransformer,
  ScheduleList,
  scheduleListFromResponse,
  scheduleOutputTransformer,
  ScheduleSummary,
  scheduleSummaryFromResponse,
} from '../interfaces/Schedule';
import { uploadDocuments } from './Document';

const scheduleBaseUrl = '/schedule/';

export const insertSchedule = async (
  schedule: ScheduleInsertRequest,
): Promise<void> =>
  api.post(scheduleBaseUrl, scheduleInsertRequestOutputTransformer(schedule));

export const restoreSchedule = async (
  scheduleID: number,
  overwrite: boolean,
): Promise<void> =>
  api.post(`${scheduleBaseUrl}restore`, { id: scheduleID, overwrite });

export const updateSchedule = async (
  schedule: Schedule,
  notes: string,
  files: File[],
): Promise<void> => {
  const attachments = await uploadDocuments(files);

  await api.put(scheduleBaseUrl, {
    ...scheduleOutputTransformer(schedule),
    notes,
    attachments,
  });
};

export const approveSchedule = async (
  scheduleID: number,
  notes: string,
  files: File[],
): Promise<void> => {
  const attachments = await uploadDocuments(files);

  await api.post(`${scheduleBaseUrl}approve`, {
    schedule_id: scheduleID,
    notes,
    attachments,
  });
};

export const rejectSchedule = async (
  scheduleID: number,
  notes: string,
  files: File[],
): Promise<void> => {
  const attachments = await uploadDocuments(files);

  await api.post(`${scheduleBaseUrl}reject`, {
    schedule_id: scheduleID,
    notes,
    attachments,
  });
};

export const rollbackScheduleApproval = async (
  scheduleID: number,
  notes: string,
  files: File[],
): Promise<void> => {
  const attachments = await uploadDocuments(files);

  await api.post(`${scheduleBaseUrl}rollback-approval`, {
    schedule_id: scheduleID,
    notes,
    attachments,
  });
};

export const downloadScheduleExcel = async (
  scheduleID: number,
): Promise<void> => {
  const response = await api.get(`${scheduleBaseUrl}export/${scheduleID}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;

  const contentDisposition = response.headers['content-disposition'];
  let filename = 'plan.xlsx';
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  fileLink.setAttribute('download', filename);

  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
};

export const getSchedules = async (
  request: ScheduleGetRequest,
): Promise<ScheduleList> => {
  const params: any = {
    page_number: request.pagination.pageNumber,
    page_size: request.pagination.pageSize,
    sort_field_name: request.sort.sortFieldName,
    is_descending: request.sort.isDescending,
  };

  if (request.filter) {
    if (isFinite(request.filter.month)) {
      params.month = request.filter.month;
    }
    if (request.filter.year) {
      params.year = request.filter.year;
    }
    if (request.filter.planName) {
      params.plan_name = request.filter.planName;
    }

    if (request.filter.departmentName) {
      params.department_name = request.filter.departmentName;
    }

    if (request.filter.sectionName) {
      params.section_name = request.filter.sectionName;
    }

    if (isFinite(request.filter.institutionID)) {
      params.institution_id = request.filter.institutionID;
    }

    if (request.filter.scheduleStatus) {
      params.status = request.filter.scheduleStatus;
    }
  }

  const response = await api.get(scheduleBaseUrl, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });

  return scheduleListFromResponse(response.data);
};

export const getScheduleSummary = async (): Promise<ScheduleSummary[]> => {
  const response = await api.get(`${scheduleBaseUrl}summary`);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((notes) => scheduleSummaryFromResponse(notes));
};

export const deleteSchedule = async (scheduleID: number): Promise<void> =>
  api.delete(scheduleBaseUrl + scheduleID.toString());
