import { ReactElement } from 'react';
import { flatten } from 'lodash';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FullDayDates } from '../../interfaces/FullDayDates';
import { Personnel } from '../../interfaces/Personnel';

interface FullDayOptionsTooltipProps {
  fullDay: FullDayDates;
  personnels: Personnel[];
  children: ReactElement<any, any>;
}

const FullDayOptionsTooltip = (props: FullDayOptionsTooltipProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <Typography>
          {t('department.min_senior_personnel_count')}:
          {props.fullDay.minimumSeniorPersonnelCount.toString()}
          <br />
          {t('department.min_personnel_count')}:
          {props.fullDay.minimumPersonnelCount.toString()}
          <br />
          {t('schedule.available_personnels')}:
          <br />
          {flatten(
            props
              .personnels!.filter((p) =>
                props.fullDay!.availablePersonnelIds.includes(p.ID!),
              )
              .map((personnel) => [`${personnel.name}`, <br />]),
          )}
        </Typography>
      }
    >
      {props.children}
    </Tooltip>
  );
};

export default FullDayOptionsTooltip;
