export interface ExportedFileHeader {
  label: string;
  value: string;
}

export interface ExportedFileRow {
  [key: string]: string;
}

export enum FileExportType {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
}
