import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { isFinite } from 'lodash';
import { Institution } from '../../interfaces/Institution';
import { getInstitutionTemplate } from '../../utils/Institution';
import { sharedColors } from '../../utils/Style';

interface InstitutionDropdownProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  options: Institution[];
  value: number;
  onChange: (newInstitution: Institution) => void;
}

const InstitutionDropdown = (props: InstitutionDropdownProps) => {
  const handleChange = (e: any) => {
    const institutionID = e.target.value as number;

    props.onChange(
      props.options.find((institution) => institution.ID === institutionID) ??
        getInstitutionTemplate(),
    );
  };

  return (
    <FormControl
      size='small'
      error={props.error}
      fullWidth={!isFinite(props.width)}
      sx={{
        width: props.width,
        backgroundColor: props.backgroundColor ?? sharedColors.white,
      }}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={props.value}
        label={props.label}
        disabled={props.disabled}
        onChange={handleChange}
        name='select'
        required={props.required}
      >
        {props.options.map((institution) => (
          <MenuItem value={institution.ID} key={institution.ID}>
            {institution.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InstitutionDropdown;
