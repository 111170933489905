import { Grid } from '@mui/material';
import { AlgorithmParameters } from '../../interfaces/Schedule';
import NumberInput from '../NumberInput';

interface AlgorithmParametersInputProps {
  algorithmParameters: AlgorithmParameters;
  onChange: (newParameters: AlgorithmParameters) => void;
}

const AlgorithmParametersInput = (props: AlgorithmParametersInputProps) => {
  const handleChangeParameter = (key: string, newValue: number) => {
    props.onChange({
      ...props.algorithmParameters,
      [key]: newValue,
    });
  };

  return (
    <Grid container columns={5} spacing={1.5}>
      {Object.keys(props.algorithmParameters).map((key) => (
        <Grid item xs={1}>
          <NumberInput
            label={key}
            value={props.algorithmParameters[key]}
            onChange={(newValue) => handleChangeParameter(key, newValue)}
            acceptFloat
            precision={2}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AlgorithmParametersInput;
