import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box } from '@mui/material';
import { selectedPageAtom } from '../../atoms/Page';
import { Institution } from '../../interfaces/Institution';
import { getInstitutions } from '../../services/Institution';
import { PageType } from '../../interfaces/Page';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import InstitutionTable from './InstitutionTable';

const InstitutionPage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [institutions, setInstitutions] = useState<Institution[]>([]);

  const refreshState = () => {
    setLoading(true);
    getInstitutions()
      .then((fetchedInstitutions) => setInstitutions(fetchedInstitutions))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedPage(PageType.INSTITUTION);

    refreshState();
  }, []);

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Box component='div' sx={sharedStyles.pageBody}>
        <InstitutionTable institutions={institutions} onChange={refreshState} />
      </Box>
    </Box>
  );
};

export default InstitutionPage;
