import { Box, FormControl } from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { sharedColors } from '../utils/Style';
import { excelMimeTypes } from '../variables/File';

interface UploadFileProps {
  setParentFile: (file: File) => void;
}

const UploadFile = (props: UploadFileProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleFile = (file: File[]) => {
    props.setParentFile(file[0]);
  };

  return (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
    >
      <FormControl component='fieldset' sx={{ display: 'flex', flexGrow: 1 }}>
        <DropzoneArea
          dropzoneClass={classes.dropzone}
          dropzoneText={t('upload.title') as string}
          acceptedFiles={[excelMimeTypes]}
          filesLimit={1}
          maxFileSize={100240000}
          showFileNames
          onChange={handleFile}
        />
      </FormControl>
    </Box>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    dropzone: {
      position: 'relative',
      padding: 2,
      minHeight: '100px',
      backgroundColor: sharedColors.gray2,
      border: 'dashed',
      borderColor: sharedColors.gray3,
      cursor: 'pointer',
    },
  }),
);

export default UploadFile;
