import { Shift } from './Shift';

export interface ShiftChangeNotes {
  before?: Shift;
  after?: Shift;
  notes: string;
  attachments: string[];
  changedAt: Date;
  changedBy: string;
}

export interface ShiftChangesMap {
  [key: string]: {
    [key: string]: ShiftChangeNotes[];
  };
}

export interface OvertimeChangeNotes {
  before: number;
  after: number;
  notes: string;
  changedAt: Date;
  changedBy: string;
}

export interface OvertimeChangesMap {
  [key: string]: {
    [key: string]: OvertimeChangeNotes[];
  };
}

export const shiftChangesMapFromResponse = (
  responseData: any,
): ShiftChangesMap =>
  Object.keys(responseData).reduce(
    (acc1, personnelName) => ({
      ...acc1,
      [personnelName]: Object.keys(responseData[personnelName]).reduce(
        (acc2, dateStr) => ({
          ...acc2,
          [dateStr]: responseData[personnelName][dateStr].map((notes: any) => ({
            before: notes.before,
            after: notes.after,
            notes: notes.notes,
            attachments: notes.attachments,
            changedAt: new Date(notes.changed_at),
            changedBy: notes.changed_by,
          })),
        }),
        {},
      ),
    }),
    {},
  );

export const overtimeChangesMapFromResponse = (
  responseData: any,
): OvertimeChangesMap =>
  Object.keys(responseData).reduce(
    (acc1, personnelName) => ({
      ...acc1,
      [personnelName]: Object.keys(responseData[personnelName]).reduce(
        (acc2, dateStr) => ({
          ...acc2,
          [dateStr]: responseData[personnelName][dateStr].map((notes: any) => ({
            before: notes.before,
            after: notes.after,
            notes: notes.notes,
            changedAt: new Date(notes.changed_at),
            changedBy: notes.changed_by,
          })),
        }),
        {},
      ),
    }),
    {},
  );
