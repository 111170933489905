import { TableCell, TableCellProps, TableSortLabel } from '@mui/material';
import { SortCriteria } from '../interfaces/SortCriteria';
import { getOrderValue } from '../utils/Sort';

interface SortableTableCellProps<T> extends TableCellProps {
  sortCriteria: SortCriteria<T>;
  fieldName: keyof T;
  onSort: () => void;
}

const SortableTableCell = <T extends any>(props: SortableTableCellProps<T>) => {
  const isActive = props.sortCriteria.fieldName === props.fieldName;

  return (
    <TableCell
      {...props}
      key={props.fieldName as string}
      sortDirection={isActive ? getOrderValue(props.sortCriteria.asc) : false}
    >
      <TableSortLabel
        active={isActive}
        direction={isActive ? getOrderValue(props.sortCriteria.asc) : undefined}
        onClick={props.onSort}
      >
        {props.children}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableTableCell;
