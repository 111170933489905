import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box } from '@mui/material';
import { selectedPageAtom } from '../../atoms/Page';
import { PageType } from '../../interfaces/Page';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import DepartmentTable from './DepartmentTable';
import { getDepartments } from '../../services/Department';
import { Department } from '../../interfaces/Department';
import { Institution } from '../../interfaces/Institution';
import { getInstitutions } from '../../services/Institution';

const DepartmentPage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [loadingInstitutions, setLoadingInstitutions] = useState(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [institutions, setInstitutions] = useState<Institution[]>([]);

  const refreshState = () => {
    setLoadingDepartments(true);
    getDepartments()
      .then((fetchedDepartments) => setDepartments(fetchedDepartments))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingDepartments(false));

    setLoadingInstitutions(true);
    getInstitutions()
      .then((fetchedInstitutions) => setInstitutions(fetchedInstitutions))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingInstitutions(false));
  };

  useEffect(() => {
    setSelectedPage(PageType.DEPARTMENT);

    refreshState();
  }, []);

  const loading = loadingInstitutions || loadingDepartments;

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Box component='div' sx={sharedStyles.pageBody}>
        <DepartmentTable
          departments={departments}
          institutions={institutions}
          onChange={refreshState}
        />
      </Box>
    </Box>
  );
};

export default DepartmentPage;
