import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';
import { flatten } from 'lodash';
import { PtoSubtype } from '../../interfaces/PtoSubtype';
import {
  availablePtoCategories,
  ptoSubtypesByCategory,
} from '../../variables/PtoSubtype';

interface PtoSubtypeDropdownProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  value: PtoSubtype;
  onChange: (newValue: PtoSubtype) => void;
}

const PtoSubtypeDropdown = (props: PtoSubtypeDropdownProps) => {
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    props.onChange(e.target?.value ?? PtoSubtype.ANNUAL);
  };

  return (
    <FormControl
      size='small'
      sx={{ width: props.width ?? 250, backgroundColor: props.backgroundColor }}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={props.value}
        label={props.label}
        disabled={props.disabled}
        onChange={handleChange}
        name='select'
        required
      >
        {flatten(
          availablePtoCategories.map((category) => [
            <ListSubheader>
              {t(`pto_subtype.category.${category}`)}
            </ListSubheader>,
            ...ptoSubtypesByCategory[category].map((subtype) => (
              <MenuItem value={subtype}>
                {t(`pto_subtype.name.${subtype}`)}
              </MenuItem>
            )),
          ]),
        )}
      </Select>
    </FormControl>
  );
};

export default PtoSubtypeDropdown;
