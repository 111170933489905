import i18next from 'i18next';
import {
  Personnel,
  PersonnelPosition,
  PersonnelUpsert,
} from '../interfaces/Personnel';
import { getStoredLanguage } from './Language';

export const getPersonnelTemplate = (): PersonnelUpsert => ({
  name: '',
  personnelID: '',
  position: PersonnelPosition.NURSE,
  canBeOnCall: true,
  notes: '',
  dayShiftHours: 8,
  institutionID: 1,
  institutionName: '',
  departmentID: 0,
  departmentName: '',
  firstName: '',
  lastName: '',
});

export const i18StringFromPosition = (position: PersonnelPosition): string => {
  switch (position) {
    case PersonnelPosition.SENIOR_NURSE:
      return i18next.t('personnel.senior_nurse');
    case PersonnelPosition.NURSE:
      return i18next.t('personnel.nurse');
    case PersonnelPosition.HEAD_NURSE:
      return i18next.t('personnel.head_nurse');
    default:
      return '';
  }
};

export const getPersonnelPositionWeight = (
  personnelPosition: PersonnelPosition,
): number => {
  switch (personnelPosition) {
    case PersonnelPosition.NURSE:
      return 1;
    case PersonnelPosition.SENIOR_NURSE:
      return 2;
    case PersonnelPosition.HEAD_NURSE:
      return 3;
    default:
      return 99999;
  }
};

export const comparePersonnels = (
  p1: Personnel,
  p2: Personnel,
  fieldName: keyof Personnel,
): number => {
  const locale = getStoredLanguage();

  switch (fieldName) {
    case 'institutionID':
      return p1.institutionID - p2.institutionID;
    case 'institutionName':
      return p1.institutionName.localeCompare(p2.institutionName, locale);
    case 'departmentID':
      return p1.departmentID - p2.departmentID;
    case 'departmentSectionID':
      return (p1.departmentSectionID ?? 0) - (p2.departmentSectionID ?? 0);
    case 'departmentName':
      return p1.departmentName.localeCompare(p2.departmentName, locale);
    case 'departmentSectionName':
      return (p1.departmentSectionName ?? '').localeCompare(
        p2.departmentSectionName ?? '',
        locale,
      );
    case 'name':
      return p1.name.localeCompare(p2.name, locale);
    case 'position':
      return (
        getPersonnelPositionWeight(p1.position) -
        getPersonnelPositionWeight(p2.position)
      );
    case 'dayShiftHours':
      return p1.dayShiftHours - p2.dayShiftHours;
    default:
      return (p1.ID ?? 0) - (p2.ID ?? 0);
  }
};

export const isDepartmentSectionNeededForPosition = (
  personnelPosition: PersonnelPosition,
): boolean => personnelPosition !== PersonnelPosition.HEAD_NURSE;

export const isOnCallAvailableForPosition = (
  personnelPosition: PersonnelPosition,
): boolean => personnelPosition !== PersonnelPosition.HEAD_NURSE;
