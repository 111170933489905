import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Shift } from '../interfaces/Shift';

interface ShiftDropdownProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  options: Shift[];
  value: Shift;
  onChange: (newValue: Shift) => void;
}

const ShiftDropdown = (props: ShiftDropdownProps) => {
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    props.onChange(e.target.value as Shift);
  };

  return (
    <FormControl
      size='small'
      sx={{ width: props.width ?? 250, backgroundColor: props.backgroundColor }}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={props.value}
        label={props.label}
        disabled={props.disabled}
        onChange={handleChange}
        name='select'
        required
      >
        {props.options.map((shift) => (
          <MenuItem value={shift} key={shift}>
            {t(`schedule.shifts.${shift}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ShiftDropdown;
