import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Institution } from '../../interfaces/Institution';
import { Department } from '../../interfaces/Department';
import { sharedColors, sharedStyles } from '../../utils/Style';
import UploadFile from '../UploadFile';
import { uploadPersonnels } from '../../services/Personnel';
import Waiting from '../Waiting';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import InstitutionDropdown from '../Institution/InstitutionDropdown';

interface UploadPersonnelDialogProps {
  open: boolean;
  institutions: Institution[];
  departments: Department[];
  onClose: () => void;
  onUpload: () => void;
}

const UploadPersonnelDialog = (props: UploadPersonnelDialogProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [institution, setInstitution] = useState<Institution | undefined>(
    undefined,
  );
  const [department, setDepartment] = useState<Department | undefined>(
    undefined,
  );
  const [departmentSection, setDepartmentSection] = useState<
    DepartmentSection | undefined
  >(undefined);

  useEffect(() => {
    setInstitution(undefined);
    setFile(undefined);
  }, [props.institutions, props.open]);

  useEffect(() => {
    setDepartment(institution?.departments[0]);
  }, [institution]);

  useEffect(() => {
    setDepartmentSection(department?.departmentSections[0]);
  }, [department]);

  const handleUpload = () => {
    if (!file || !departmentSection) {
      return;
    }

    setLoading(true);
    uploadPersonnels(file, departmentSection.ID!)
      .then(() => {
        toast.success(t('personnel.successful.upload'));
        props.onUpload();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeDepartment = (e: any) => {
    setDepartment(props.departments.find((d) => d.name === e.target.value));
  };

  const handleChangeDepartmentSection = (e: any) => {
    setDepartmentSection(
      department?.departmentSections?.find(
        (section) => section.name === e.target.value,
      ),
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {t('personnel.dialog.upload')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <InstitutionDropdown
            label={t('personnel.institution') as string}
            backgroundColor={sharedColors.white}
            error={!institution}
            options={props.institutions}
            value={institution?.ID ?? -1}
            onChange={setInstitution}
          />
        </Box>
        <Box
          component='div'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            mt: 1.5,
            mb: 1.5,
          }}
        >
          <FormControl
            disabled={!institution}
            size='small'
            sx={{ width: 230, mr: 1.5 }}
          >
            <InputLabel>{t('personnel.department')}</InputLabel>
            <Select
              value={department?.name ?? ''}
              label={t('personnel.department')}
              onChange={handleChangeDepartment}
              name='select'
              required
            >
              {props.departments
                .filter((d) => d.institutionID === institution?.ID)
                .map((d) => (
                  <MenuItem value={d.name} key={d.ID}>
                    {d.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl disabled={!department} size='small' sx={{ width: 230 }}>
            <InputLabel>{t('personnel.department_section')}</InputLabel>
            <Select
              value={departmentSection?.name ?? ''}
              label={t('personnel.department_section')}
              disabled={!department}
              onChange={handleChangeDepartmentSection}
              name='select'
              required
            >
              {department?.departmentSections.map((section) => (
                <MenuItem value={section.name} key={section.ID}>
                  {section.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <UploadFile setParentFile={setFile} />
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Box component='div' sx={{ flexGrow: 1, display: 'flex' }}>
          <Link
            variant='body2'
            href={'/personel_template.xlsx'}
            sx={sharedStyles.buttonText}
          >
            {t('shared.download_template')}
          </Link>
        </Box>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!file || !departmentSection}
          onClick={handleUpload}
          sx={sharedStyles.buttonText}
        >
          {t('shared.upload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPersonnelDialog;
