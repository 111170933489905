import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getMonthNames } from '../utils/Date';

interface MonthDropdownProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  allowSelectAll?: boolean;
  value: number | null;
  onChange: (newValue: number | null) => void;
}

const MonthDropdown = (props: MonthDropdownProps) => {
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    const newValue = e.target.value as number;
    props.onChange(newValue < 0 ? null : newValue);
  };

  return (
    <FormControl
      size='small'
      sx={{ width: props.width ?? 250, backgroundColor: props.backgroundColor }}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={props.value ?? -1}
        label={props.label}
        disabled={props.disabled}
        onChange={handleChange}
        name='select'
        required
      >
        {props.allowSelectAll && (
          <MenuItem value={-1} key={-1}>
            {t('shared.all')}
          </MenuItem>
        )}
        {getMonthNames().map((name, i) => (
          <MenuItem value={i} key={i}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MonthDropdown;
