import { isArray } from 'lodash';
import {
  Institution,
  institutionFromResponse,
  institutionOutputTransformer,
} from '../interfaces/Institution';
import api from './Api';

const institutionBaseUrl = '/institution/';

export const upsertInstitution = async (
  institution: Institution,
): Promise<void> =>
  api.put(institutionBaseUrl, institutionOutputTransformer(institution));

export const getInstitutions = async (): Promise<Institution[]> => {
  const response = await api.get(institutionBaseUrl);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((institution) =>
    institutionFromResponse(institution),
  );
};

export const deleteInstitution = async (institutionID: number): Promise<void> =>
  api.delete(institutionBaseUrl + institutionID.toString());
