import {
  Schedule,
  ScheduleSortableFields,
  ScheduleSortCriteria,
} from '../interfaces/Schedule';
import { SortCriteria } from '../interfaces/SortCriteria';

export const getPriorityByOrder = (asc: boolean) => (asc ? -1 : 1);
export const getOrderValue = (asc: boolean): 'asc' | 'desc' =>
  asc ? 'asc' : 'desc';

export const getScheduleSortRequest = (
  criteria: SortCriteria<Schedule>,
): ScheduleSortCriteria => {
  if (criteria.fieldName === 'audit')
    return {
      sortFieldName: ScheduleSortableFields.LAST_UPDATED_AT,
      isDescending: !criteria.asc,
    };
  if (criteria.fieldName === 'name')
    return {
      sortFieldName: ScheduleSortableFields.NAME,
      isDescending: !criteria.asc,
    };
  if (criteria.fieldName === 'institutionName')
    return {
      sortFieldName: ScheduleSortableFields.INSTITUTION,
      isDescending: !criteria.asc,
    };
  if (criteria.fieldName === 'department')
    return {
      sortFieldName: ScheduleSortableFields.DEPARTMENT,
      isDescending: !criteria.asc,
    };
  if (criteria.fieldName === 'departmentSection')
    return {
      sortFieldName: ScheduleSortableFields.SECTION,
      isDescending: !criteria.asc,
    };
  if (criteria.fieldName === 'startAt')
    return {
      sortFieldName: ScheduleSortableFields.START_AT,
      isDescending: !criteria.asc,
    };
  if (criteria.fieldName === 'endAt')
    return {
      sortFieldName: ScheduleSortableFields.END_AT,
      isDescending: !criteria.asc,
    };
  if (criteria.fieldName === 'status')
    return {
      sortFieldName: ScheduleSortableFields.STATUS,
      isDescending: !criteria.asc,
    };

  return {
    sortFieldName: ScheduleSortableFields.LAST_UPDATED_AT,
    isDescending: true,
  };
};
