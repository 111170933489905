import { isArray } from 'lodash';
import {
  DepartmentSection,
  departmentSectionFromResponse,
  departmentSectionOutputTransformer,
} from '../interfaces/DepartmentSection';
import api from './Api';

const departmentSectionBaseUrl = '/department-section/';

export const upsertDepartmentSection = async (
  departmentSection: DepartmentSection,
): Promise<void> =>
  api.put(
    departmentSectionBaseUrl,
    departmentSectionOutputTransformer(departmentSection),
  );

export const getDepartmentSections = async (): Promise<DepartmentSection[]> => {
  const response = await api.get(departmentSectionBaseUrl);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((departmentSection) =>
    departmentSectionFromResponse(departmentSection),
  );
};

export const deleteDepartmentSection = async (
  departmentSectionID: number,
): Promise<void> =>
  api.delete(departmentSectionBaseUrl + departmentSectionID.toString());
