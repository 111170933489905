import { isEmpty } from 'lodash';
import api from './Api';

const documentBaseUrl = '/document/';

export const uploadDocuments = async (files: File[]): Promise<string[]> => {
  if (isEmpty(files)) {
    return [];
  }

  const bodyFormData = new FormData();
  files.forEach((file) => bodyFormData.append('files', file, file.name));

  const response = await api.post(`${documentBaseUrl}upload`, bodyFormData);
  return response.data?.filenames ?? [];
};

export const downloadDocument = async (filename: string): Promise<void> => {
  const response = await api.get(`${documentBaseUrl}download/${filename}`, {
    responseType: 'blob',
  });

  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  });
  const fileURL = window.URL.createObjectURL(blob);
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', filename);

  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
};
