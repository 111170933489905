import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { ScheduleNotes } from '../../interfaces/ScheduleNotes';
import {
  addScheduleNotes,
  getScheduleNotes,
} from '../../services/ScheduleNotes';
import ScheduleNotesCard from './ScheduleNotesCard';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import UploadNoteAttachmentDialog from './UploadNoteAttachmentDialog';

interface ScheduleNotesDialogProps {
  open: boolean;
  scheduleID: number;
  onComment: () => void;
  onClose: () => void;
}

const ScheduleNotesDialog = (props: ScheduleNotesDialogProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [scheduleNotes, setScheduleNotes] = useState<ScheduleNotes[]>([]);
  const [showAttachmentsDialog, setShowAttachmentsDialog] = useState(false);

  const refreshNotes = () => {
    setLoading(true);
    getScheduleNotes(props.scheduleID)
      .then((notes) => setScheduleNotes(notes))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setMessage('');
    if (props.scheduleID >= 0 && props.open) {
      refreshNotes();
    } else {
      setScheduleNotes([]);
    }
  }, [props.open]);

  const handleSend = () => {
    setLoading(true);
    addScheduleNotes(props.scheduleID, message, files)
      .then(() => {
        toast.success(t('schedule.notes_dialog.successful'));
        props.onComment();
        setMessage('');
        setFiles([]);
        refreshNotes();
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  const renderNotes = () => {
    if (isEmpty(scheduleNotes)) {
      return (
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {t('schedule.notes_dialog.empty')}
        </Typography>
      );
    }

    return (
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'auto',
          maxHeight: 300,
        }}
      >
        {scheduleNotes.map((notes) => (
          <ScheduleNotesCard scheduleNotes={notes} />
        ))}
      </Box>
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <UploadNoteAttachmentDialog
        open={showAttachmentsDialog}
        files={files}
        onClose={() => setShowAttachmentsDialog(false)}
        onConfirm={(newFiles) => {
          setFiles(newFiles);
          setShowAttachmentsDialog(false);
        }}
      />
      <DialogTitle>{t('schedule.notes_dialog.title')}</DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        {loading ? (
          [
            <Skeleton variant='rectangular' width={400} height={60} />,
            <Skeleton
              variant='rectangular'
              width={400}
              height={60}
              sx={{ mt: 1.5 }}
            />,
            <Skeleton
              variant='rectangular'
              width={400}
              height={60}
              sx={{ mt: 1.5 }}
            />,
          ]
        ) : (
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            {renderNotes()}
            {hasWriteAccess(tokenData, AccessLevel.DEPARTMENT_SECTION) && (
              <Box
                component='div'
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexGrow: 1,
                  alignItems: 'center',
                  mt: 1.5,
                }}
              >
                <IconButton
                  color='primary'
                  onClick={() => setShowAttachmentsDialog(true)}
                >
                  <Badge
                    badgeContent={files.length}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: sharedColors.statusGreen,
                        color: sharedColors.white,
                        fontSize: '12px',
                      },
                    }}
                  >
                    <AddIcon />
                  </Badge>
                </IconButton>
                <TextField
                  value={message}
                  onChange={(e) => setMessage(e.target?.value ?? '')}
                  size='small'
                  fullWidth
                  multiline
                />
                <IconButton
                  color='primary'
                  disabled={isEmpty(message.trim())}
                  onClick={handleSend}
                  sx={{ ml: 1.5 }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleNotesDialog;
