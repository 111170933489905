import { atom } from 'recoil';
import { TokenData } from '../interfaces/TokenData';

export const tokenDataAtom = atom({
  key: 'tokenData',
  default: {
    roles: [],
    institution: [],
    department: [],
    departmentSection: [],
  } as TokenData,
});
