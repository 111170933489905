import { t } from 'i18next';

export interface AlgorithmParameterMultipliers {
  overtimeHours: number;
  fullDayNight: number;
  nonFullDayNight: number;
}

export const getDefaultAlgorithmParameterMultipliers =
  (): AlgorithmParameterMultipliers => {
    return {
      overtimeHours: 1,
      fullDayNight: 1,
      nonFullDayNight: 1,
    };
  };

export const algorithmParameterMultipliersOutputTransformer = (
  multipliers: AlgorithmParameterMultipliers,
) => ({
  overtime_hours: multipliers.overtimeHours,
  full_day_night: multipliers.fullDayNight,
  non_full_day_night: multipliers.nonFullDayNight,
});

export const algorithmParameterMultipliersFromResponse = (
  responseData: any,
): AlgorithmParameterMultipliers => ({
  overtimeHours: responseData.overtime_hours,
  fullDayNight: responseData.full_day_night,
  nonFullDayNight: responseData.non_full_day_night,
});

export const getOvertimeMultiplierLabelFromValue = (value: number): string => {
  switch (value) {
    case 1:
      return t('schedule.prioritization.normal');
    case 1.25:
      return t('schedule.prioritization.high');
    case 1.5:
      return t('schedule.prioritization.very_high');
    case 1.75:
      return t('schedule.prioritization.max');
    default:
      return t('schedule.prioritization.normal');
  }
};

export const getFullDayMultiplierLabelFromValue = (value: number): string => {
  switch (value) {
    case 1:
      return t('schedule.prioritization.normal');
    case 1.1:
      return t('schedule.prioritization.high');
    case 1.2:
      return t('schedule.prioritization.very_high');
    case 1.3:
      return t('schedule.prioritization.max');
    default:
      return t('schedule.prioritization.normal');
  }
};
