import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Pagination } from '../../interfaces/Pagination';
import { sharedColors, sharedStyles } from '../../utils/Style';
import UpsertDepartmentDialog from './UpsertDepartmentDialog';
import { Department } from '../../interfaces/Department';
import { SortCriteria } from '../../interfaces/SortCriteria';
import { compareDepartments } from '../../utils/Department';
import { getPriorityByOrder } from '../../utils/Sort';
import SortableTableCell from '../SortableTableCell';
import { Institution } from '../../interfaces/Institution';
import InstitutionAutocomplete from '../Institution/InstitutionAutocomplete';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';
import { tokenDataAtom } from '../../atoms/TokenData';

interface DepartmentTableProps {
  departments: Department[];
  institutions: Institution[];
  onChange: () => void;
}

const DepartmentTable = (props: DepartmentTableProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [searchedValue, setSearchedValue] = useState('');
  const [searchedInstitution, setSearchedInstitution] =
    useState<Institution | null>(null);
  const [clickedDepartment, setClickedDepartment] = useState<
    Department | undefined
  >(undefined);
  const [showNewDepartmentDialog, setShowNewDepartmentDialog] = useState(false);
  const [sortCriteria, setSortCriteria] = useState<SortCriteria<Department>>({
    fieldName: 'institutionName',
    asc: true,
  });

  useEffect(() => {
    setPagination({ ...pagination, pageNumber: 0 });
  }, [searchedValue, searchedInstitution]);

  const handleSearch = (e: any) => {
    setSearchedValue(e.target.value ?? '');
  };

  const handleAddDepartment = () => {
    setShowNewDepartmentDialog(false);
    setClickedDepartment(undefined);
    props.onChange();
  };

  const handleCloseUpsertDepartmentDialog = () => {
    setClickedDepartment(undefined);
    setShowNewDepartmentDialog(false);
  };

  const handleSort = (fieldName: keyof Department) => {
    setSortCriteria({
      fieldName,
      asc: fieldName === sortCriteria.fieldName ? !sortCriteria.asc : true,
    });
  };

  let filteredRows = props.departments.filter((department) =>
    department.name.toLowerCase().includes(searchedValue.toLowerCase()),
  );
  if (searchedInstitution) {
    filteredRows = filteredRows.filter(
      (department) => department.institutionID === searchedInstitution.ID,
    );
  }
  filteredRows = filteredRows.sort((d1, d2) =>
    compareDepartments(d1, d2, sortCriteria.fieldName) < 0
      ? getPriorityByOrder(sortCriteria.asc)
      : -getPriorityByOrder(sortCriteria.asc),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  const readOnly = !hasWriteAccess(tokenData, AccessLevel.DEPARTMENT);

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      {!readOnly && (
        <UpsertDepartmentDialog
          institutions={props.institutions}
          open={showNewDepartmentDialog || !!clickedDepartment}
          clickedDepartment={clickedDepartment}
          onClose={handleCloseUpsertDepartmentDialog}
          onModify={handleAddDepartment}
        />
      )}
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {t('department.title')}
      </Typography>
      <Box
        component='div'
        sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', mt: 1.5 }}
      >
        <TextField
          id='search-text-field'
          size='small'
          label={t('shared.search')}
          variant='outlined'
          value={searchedValue}
          onChange={handleSearch}
          sx={{ width: 250, backgroundColor: sharedColors.white, mr: 1.5 }}
        />
        <InstitutionAutocomplete
          label={t('department.institution') as string}
          width={300}
          options={props.institutions}
          value={searchedInstitution}
          onChange={setSearchedInstitution}
          backgroundColor={sharedColors.white}
        />
        <Box component='div' sx={{ flexGrow: 1 }} />
        {hasWriteAccess(tokenData, AccessLevel.INSTITUTION) && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowNewDepartmentDialog(true)}
            sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto' }}
          >
            {t('department.new')}
          </Button>
        )}
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell
                colSpan={3}
                align='center'
                sx={{ borderLeft: '1px groove #dbdbdb' }}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.min_personnel_count')}
                </Typography>
              </TableCell>
              <TableCell
                colSpan={3}
                align='center'
                sx={{ borderLeft: '1px groove #dbdbdb' }}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.min_senior_personnel_count')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='name'
                onSort={() => handleSort('name')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.name')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='institutionName'
                onSort={() => handleSort('institutionName')}
                sx={{
                  borderLeft: '1px groove #dbdbdb',
                  borderRight: '1px groove #dbdbdb',
                }}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.institution')}
                </Typography>
              </SortableTableCell>
              <TableCell
                align='center'
                sx={{
                  borderLeft: '1px groove #dbdbdb',
                  borderRight: '1px groove #dbdbdb',
                }}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.total_personnel_count')}
                </Typography>
              </TableCell>
              <TableCell
                align='center'
                sx={{ borderLeft: '1px groove #dbdbdb' }}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {`${t('department.personnel_count.day')}*`}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.personnel_count.night')}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.personnel_count.weekend')}
                </Typography>
              </TableCell>
              <TableCell
                align='center'
                sx={{ borderLeft: '1px groove #dbdbdb' }}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {`${t('department.personnel_count.day')}*`}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.personnel_count.night')}
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>
                  {t('department.personnel_count.weekend')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow
                key={row.ID}
                sx={readOnly ? undefined : sharedStyles.editableRow}
              >
                <TableCell onClick={() => setClickedDepartment(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => setClickedDepartment(row)}
                  sx={{ borderLeft: '1px groove #dbdbdb' }}
                >
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.institutionName}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartment(row)}
                  sx={{
                    borderLeft: '1px groove #dbdbdb',
                    borderRight: '1px groove #dbdbdb',
                  }}
                >
                  <Typography sx={sharedStyles.tableStringFieldNoFlex}>
                    {row.totalPersonnels.toString()}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartment(row)}
                  sx={{ borderLeft: '1px groove #dbdbdb' }}
                >
                  <Typography sx={sharedStyles.tableStringFieldNoFlex}>
                    {row.minimumPersonnelCounts.day}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartment(row)}
                >
                  <Typography sx={sharedStyles.tableStringFieldNoFlex}>
                    {row.minimumPersonnelCounts.night}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartment(row)}
                >
                  <Typography sx={sharedStyles.tableStringFieldNoFlex}>
                    {row.minimumPersonnelCounts.weekend}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartment(row)}
                  sx={{ borderLeft: '1px groove #dbdbdb' }}
                >
                  <Typography sx={sharedStyles.tableStringFieldNoFlex}>
                    {row.minimumSeniorPersonnelCounts.day}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartment(row)}
                >
                  <Typography sx={sharedStyles.tableStringFieldNoFlex}>
                    {row.minimumSeniorPersonnelCounts.night}
                  </Typography>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => setClickedDepartment(row)}
                >
                  <Typography sx={sharedStyles.tableStringFieldNoFlex}>
                    {row.minimumSeniorPersonnelCounts.weekend}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
      <Typography
        sx={{ ...sharedStyles.subtitle2, mt: 1.5, color: sharedColors.gray6 }}
      >
        {`*${t('department.except_head_nurse')}`}
      </Typography>
    </Box>
  );
};

export default DepartmentTable;
