import { isEmpty } from 'lodash';
import {
  AccessLevel,
  UserCreateRequest,
  UserGetRequest,
  UserRole,
} from '../interfaces/User';
import { Institution } from '../interfaces/Institution';

export const getAccessLevel = (roles: UserRole[]): AccessLevel => {
  if (roles.includes(UserRole.ADMIN)) {
    return AccessLevel.ADMIN;
  }
  if (roles.includes(UserRole.INSTITUTION_ACCESS)) {
    return AccessLevel.INSTITUTION;
  }
  if (roles.includes(UserRole.DEPARTMENT_ACCESS)) {
    return AccessLevel.DEPARTMENT;
  }

  return AccessLevel.DEPARTMENT_SECTION;
};

export const getRolesListByAccessLevel = (
  accessLevel: AccessLevel,
): UserRole[] => {
  switch (accessLevel) {
    case AccessLevel.ADMIN:
      return [UserRole.ADMIN];
    case AccessLevel.INSTITUTION:
      return [UserRole.INSTITUTION_ACCESS];
    case AccessLevel.DEPARTMENT:
      return [UserRole.DEPARTMENT_ACCESS];
    case AccessLevel.DEPARTMENT_SECTION:
      return [];
    default:
      return [];
  }
};

export const getDefaultUserFilters = (): UserGetRequest => ({
  pageSize: 10,
  pageNumber: 0,
  institutionID: -1,
});

export const getUserCreateTemplate = (
  institutions: Institution[],
): UserCreateRequest => ({
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  institutions: isEmpty(institutions) ? [] : [institutions[0].ID!.toString()],
  departments: [],
  departmentSections: [],
  username: '',
  password: '',
  isTempPassword: true,
});

export const isValidUser = (user: UserCreateRequest): boolean => {
  const accessLevel = getAccessLevel(user.roles);

  return (
    !isEmpty(user.firstName.trim()) &&
    !isEmpty(user.lastName.trim()) &&
    !isEmpty(user.email.trim()) &&
    !isEmpty(user.institutions) &&
    !isEmpty(user.username.trim()) &&
    (accessLevel !== AccessLevel.DEPARTMENT || !isEmpty(user.departments)) &&
    (accessLevel !== AccessLevel.DEPARTMENT_SECTION ||
      !isEmpty(user.departmentSections))
  );
};
