import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { cloneDeep, isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';
import {
  AccessLevel,
  User,
  UserCreateRequest,
  userCreateRequestFromUser,
  UserRole,
  userUpdateRequestFromCreateRequest,
} from '../../interfaces/User';
import { Institution } from '../../interfaces/Institution';
import { Department } from '../../interfaces/Department';
import {
  getAccessLevel,
  getRolesListByAccessLevel,
  getUserCreateTemplate,
  isValidUser,
} from '../../utils/User';
import { createUser, resetPassword, updateUser } from '../../services/User';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { validatePassword } from '../../utils/Password';
import InstitutionDropdown from '../Institution/InstitutionDropdown';
import DepartmentAutocomplete from '../Department/DepartmentAutocomplete';
import { DepartmentSection } from '../../interfaces/DepartmentSection';
import DepartmentSectionAutocomplete from '../DepartmentSection/DepartmentSectionAutocomplete';
import { tokenDataAtom } from '../../atoms/TokenData';
import ConfirmDialog from '../ConfirmDialog';
import PasswordResetResultDialog from './PasswordResetResultDialog';

interface UpsertUserDialogProps {
  open: boolean;
  clickedUser?: User;
  institutions: Institution[];
  departments: Department[];
  departmentSections: DepartmentSection[];
  onClose: () => void;
  onUpsert: () => void;
}

const UpsertUserDialog = (props: UpsertUserDialogProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(
    getUserCreateTemplate(props.institutions),
  );
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tempPassword, setTempPassword] = useState<string | undefined>(
    undefined,
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);

  useEffect(() => {
    setCurrentState(
      props.clickedUser
        ? userCreateRequestFromUser(props.clickedUser)
        : getUserCreateTemplate(props.institutions),
    );
    setConfirmPassword('');
  }, [props.clickedUser, props.open, props.institutions]);

  const handleConfirm = () => {
    setLoading(true);

    let promise: Promise<void>;
    if (props.clickedUser) {
      promise = updateUser(
        props.clickedUser.id,
        userUpdateRequestFromCreateRequest(currentState),
      );
    } else {
      promise = createUser(currentState);
    }

    promise
      .then(() => {
        toast.success(
          props.clickedUser
            ? t('user.successful.update')
            : t('user.successful.add'),
        );
        props.onUpsert();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleResetPassword = () => {
    setShowResetDialog(false);

    if (!props.clickedUser) {
      return;
    }

    setLoading(true);
    resetPassword(props.clickedUser.id)
      .then((newPassword) => setTempPassword(newPassword))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeTextField = (
    e: any,
    fieldName: keyof UserCreateRequest,
  ) => {
    setCurrentState({ ...currentState, [fieldName]: e.target?.value ?? '' });
  };

  const handleToggleTempPassword = () => {
    setCurrentState({
      ...currentState,
      isTempPassword: !currentState.isTempPassword,
    });
  };

  const handleToggleWritePermission = () => {
    const newRoles = cloneDeep(currentState.roles);
    if (currentState.roles.includes(UserRole.WRITE)) {
      newRoles.splice(currentState.roles.indexOf(UserRole.WRITE), 1);
    } else {
      newRoles.push(UserRole.WRITE);
    }

    setCurrentState({
      ...currentState,
      roles: newRoles,
    });
  };

  const handleChangeInstitution = (newInstitution: Institution) => {
    setCurrentState({
      ...currentState,
      institutions: [newInstitution.ID!.toString()],
      departments: [],
      departmentSections: [],
    });
  };

  const selectedInstitution = isEmpty(currentState.institutions)
    ? undefined
    : props.institutions.find(
        (institution) =>
          institution.ID?.toString() === currentState.institutions[0],
      );

  const accessLevel = getAccessLevel(currentState.roles);

  const handleChangeAccessLevel = (e: any) => {
    setCurrentState({
      ...currentState,
      roles: [
        ...getRolesListByAccessLevel(e.target.value as AccessLevel),
        ...(currentState.roles.includes(UserRole.WRITE)
          ? [UserRole.WRITE]
          : []),
      ],
      departments: [],
      departmentSections: [],
    });
  };

  const handleChangeDepartment = (newDepartment: Department | null) => {
    setCurrentState({
      ...currentState,
      departments: newDepartment ? [newDepartment.ID!.toString()] : [],
    });
  };

  const handleChangeDepartmentSection = (
    newDepartmentSection: DepartmentSection | null,
  ) => {
    setCurrentState({
      ...currentState,
      departmentSections: newDepartmentSection
        ? [newDepartmentSection.ID!.toString()]
        : [],
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <ConfirmDialog
        content={t('user.confirm_reset_password')}
        open={showResetDialog}
        onClose={() => setShowResetDialog(false)}
        onConfirm={handleResetPassword}
        color='error'
      />
      <PasswordResetResultDialog
        newPassword={tempPassword}
        username={props.clickedUser?.username}
        onClose={() => setTempPassword(undefined)}
      />
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {props.clickedUser ? t('user.dialog.update') : t('user.dialog.add')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <TextField
            label={t('user.first_name')}
            value={currentState.firstName}
            onChange={(e) => handleChangeTextField(e, 'firstName')}
            size='small'
            error={isEmpty(currentState.firstName.trim())}
            sx={{ width: 230, mr: 1.5 }}
          />
          <TextField
            label={t('user.last_name')}
            value={currentState.lastName}
            onChange={(e) => handleChangeTextField(e, 'lastName')}
            size='small'
            error={isEmpty(currentState.lastName.trim())}
            sx={{ width: 230 }}
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <TextField
            label={t('user.username')}
            value={currentState.username}
            disabled={!!props.clickedUser}
            onChange={(e) => handleChangeTextField(e, 'username')}
            size='small'
            error={isEmpty(currentState.username.trim())}
            sx={{ width: 230, mr: 1.5 }}
          />
          <TextField
            label={t('user.email')}
            value={currentState.email}
            onChange={(e) => handleChangeTextField(e, 'email')}
            size='small'
            error={isEmpty(currentState.email.trim())}
            sx={{ width: 230 }}
          />
        </Box>
        {!props.clickedUser && [
          <Box
            component='div'
            sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
          >
            <Tooltip title={t('user.password_tooltip')}>
              <TextField
                label={t('user.password')}
                value={currentState.password}
                onChange={(e) => handleChangeTextField(e, 'password')}
                size='small'
                type={showPassword ? undefined : 'password'}
                error={!validatePassword(currentState.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        color={showPassword ? 'primary' : 'inherit'}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: 230, mr: 1.5 }}
              />
            </Tooltip>
            <TextField
              label={t('user.confirm_password')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              size='small'
              type='password'
              error={currentState.password !== confirmPassword}
              sx={{ width: 230 }}
            />
          </Box>,
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentState.isTempPassword}
                  onChange={handleToggleTempPassword}
                />
              }
              label={
                <Typography sx={sharedStyles.subtitle2}>
                  {t('user.temp_password')}
                </Typography>
              }
            />
          </Box>,
        ]}
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <InstitutionDropdown
            label={t('user.institution') as string}
            backgroundColor={sharedColors.white}
            options={props.institutions}
            value={selectedInstitution?.ID ?? -1}
            disabled={currentState.username === tokenData.username}
            onChange={handleChangeInstitution}
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <FormControl size='small' sx={{ width: 230, mr: 1.5 }}>
            <InputLabel>{t('user.access_level.access_level')}</InputLabel>
            <Select
              value={accessLevel}
              label={t('user.access_level.access_level')}
              disabled={currentState.username === tokenData.username}
              onChange={handleChangeAccessLevel}
              name='select'
              required
            >
              {[
                AccessLevel.INSTITUTION,
                AccessLevel.DEPARTMENT,
                AccessLevel.DEPARTMENT_SECTION,
              ].map((level) => (
                <MenuItem value={level} key={level}>
                  {t(`user.access_level.${level}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {accessLevel === AccessLevel.DEPARTMENT && (
            <DepartmentAutocomplete
              width={230}
              label={t('user.department') as string}
              error={isEmpty(currentState.departments)}
              options={props.departments.filter(
                (department) =>
                  selectedInstitution &&
                  department.institutionID === selectedInstitution.ID,
              )}
              value={
                props.departments.find((department) =>
                  currentState.departments.includes(
                    department.ID?.toString() ?? '',
                  ),
                ) ?? null
              }
              disabled={currentState.username === tokenData.username}
              onChange={handleChangeDepartment}
            />
          )}
          {accessLevel === AccessLevel.DEPARTMENT_SECTION && (
            <DepartmentSectionAutocomplete
              width={230}
              label={t('user.department_section') as string}
              error={isEmpty(currentState.departmentSections)}
              options={props.departmentSections.filter(
                (departmentSection) =>
                  selectedInstitution &&
                  departmentSection.institutionID === selectedInstitution.ID,
              )}
              value={
                props.departmentSections.find((departmentSection) =>
                  currentState.departmentSections.includes(
                    departmentSection.ID?.toString() ?? '',
                  ),
                ) ?? null
              }
              disabled={currentState.username === tokenData.username}
              onChange={handleChangeDepartmentSection}
            />
          )}
        </Box>
        <Box
          component='div'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={currentState.roles.includes(UserRole.WRITE)}
                disabled={currentState.username === tokenData.username}
                onChange={handleToggleWritePermission}
              />
            }
            label={
              <Typography sx={sharedStyles.subtitle2}>
                {t('user.access_level.write_access')}
              </Typography>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        {props.clickedUser && currentState.username !== tokenData.username && (
          <Button
            variant='contained'
            color='error'
            onClick={() => setShowResetDialog(true)}
            sx={sharedStyles.buttonText}
          >
            {t('user.reset_password')}
          </Button>
        )}
        <Box component='div' sx={{ display: 'flex', flexGrow: 1 }} />
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={
            !isValidUser(currentState) ||
            currentState.password !== confirmPassword
          }
          onClick={handleConfirm}
          sx={sharedStyles.buttonText}
        >
          {props.clickedUser ? t('user.dialog.update') : t('user.dialog.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertUserDialog;
