import { isEmpty } from 'lodash';
import { DateRange } from '../interfaces/DateRange';

export const extractYearsFromDateRanges = (
  dateRanges: DateRange[],
): number[] => {
  if (isEmpty(dateRanges)) {
    return [];
  }

  const minMaxYear = dateRanges.reduce(
    (acc, dateRange) => ({
      minYear: Math.min(
        acc.minYear,
        dateRange.startAt.getFullYear(),
        dateRange.endAt.getFullYear(),
      ),
      maxYear: Math.max(
        acc.maxYear,
        dateRange.startAt.getFullYear(),
        dateRange.endAt.getFullYear(),
      ),
    }),
    {
      minYear: dateRanges[0].startAt.getFullYear(),
      maxYear: dateRanges[0].startAt.getFullYear(),
    },
  );

  const years: number[] = [];
  for (let i = minMaxYear.minYear; i <= minMaxYear.maxYear; i += 1) {
    years.push(i);
  }

  return years;
};
