export const sharedColors = {
  black: '#000000',
  blue1: '#E3F2FD',
  blue2: '#BBDEFB',
  blue4: '#2196F3',
  blue5: '#1976D2',
  blue6: '#0D47A1',
  blue7: '#001D6C',
  gray1: '#FAFAFA',
  gray2: '#F5F5F5',
  gray3: '#ececec',
  gray4: '#ADADAD',
  gray5: '#757575',
  gray6: '#424242',
  gray7: '#232323',
  gray8: '#EEEEEE',
  purple1: '#F3E5F5',
  purple4: '#BA68C8',
  statusGreen: '#008A00',
  statusGreenLightest: '#EBF6EB',
  statusRed: '#AE0000',
  statusRedLightest: '#F7EDED',
  statusYellow: '#EAB600',
  statusYellowDark: '#382C00',
  statusYellowLightest: '#FFFEF1',
  statusOrange: '#ECB010',
  statusOrangeDarkest: '#C18F0A',
  white: '#FFFFFF',
  infoYellow: '#EAB600',
  yellowLightest: '#FFFEF1',
  yellowDark: '#382C00',
  primary: '#3F51B5',
  primaryDark: '#002884',
};

export const sharedStyles = {
  iconColor: {
    color: sharedColors.gray4,
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    textTransform: 'none',
  },
  caption: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '13px',
  },
  h2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '33px',
  },
  h3: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '28px',
  },
  h5: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
  },
  h6: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
  },
  h7: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '17px',
  },
  body1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
  },
  body2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
  },
  overline: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
  },
  outerTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  pageBody: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 5,
  },
  pageHeader: {
    backgroundColor: sharedColors.gray1,
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingX: 4.5,
    paddingY: 3.5,
    mt: 0.1,
  },
  subtitle1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
  },
  subtitle2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: 2,
    backgroundColor: sharedColors.gray1,
  },
  tableStringField: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '32px',
    flexDirection: 'row',
    display: 'flex',
  },
  tableStringFieldNoFlex: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '32px',
  },
  columnLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  editableRow: {
    cursor: 'pointer',
  },
  hidden: {
    display: 'none',
  },
  resultChartContainer: {
    flexGrow: 1,
    mt: 2.5,
    p: 2.5,
  },
};
