import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import MemoryIcon from '@mui/icons-material/Memory';
import GppBadIcon from '@mui/icons-material/GppBad';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Typography } from '@mui/material';
import { ScheduleStatus } from '../../interfaces/Schedule';
import { sharedColors, sharedStyles } from '../../utils/Style';

interface ScheduleStatusFieldProps {
  scheduleStatus: ScheduleStatus;
}

const ScheduleStatusField = (props: ScheduleStatusFieldProps) => {
  const { t } = useTranslation();

  const getColor = (): string => {
    switch (props.scheduleStatus) {
      case ScheduleStatus.QUEUED:
        return sharedColors.primaryDark;
      case ScheduleStatus.RUNNING:
        return sharedColors.blue4;
      case ScheduleStatus.FINISHED:
      case ScheduleStatus.PENDING_SUPERVISOR:
        return sharedColors.statusYellow;
      case ScheduleStatus.APPROVED:
        return sharedColors.statusGreen;
      case ScheduleStatus.REJECTED:
        return sharedColors.statusOrange;
      default:
        return sharedColors.black;
    }
  };

  const getIcon = () => {
    switch (props.scheduleStatus) {
      case ScheduleStatus.QUEUED:
        return <HourglassEmptyIcon />;
      case ScheduleStatus.RUNNING:
        return <MemoryIcon />;
      case ScheduleStatus.FINISHED:
        return <RadioButtonUncheckedIcon />;
      case ScheduleStatus.PENDING_SUPERVISOR:
        return <CheckCircleOutlineIcon />;
      case ScheduleStatus.REJECTED:
        return <GppBadIcon />;
      case ScheduleStatus.APPROVED:
        return <VerifiedUserIcon />;
      default:
        return <QuestionMarkIcon />;
    }
  };

  return (
    <Typography
      sx={{
        ...sharedStyles.tableStringField,
        alignItems: 'center',
        color: getColor(),
      }}
    >
      {getIcon()}
      <Box component='div' sx={{ marginLeft: 0.5 }}>
        {t(`schedule.status.${props.scheduleStatus}`)}
      </Box>
    </Typography>
  );
};

export default ScheduleStatusField;
