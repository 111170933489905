import { DateTime } from 'luxon';
import { isFinite } from 'lodash';
import { PersonnelPosition } from './Personnel';
import { Shift } from './Shift';

export interface PersonnelOnDuty {
  name: string;
  position: PersonnelPosition;
  shift: Shift;
  institutionID: number;
  departmentID: number;
  departmentSectionID?: number;
  institutionName: string;
  departmentName: string;
  departmentSectionName?: string;
}

export const personnelOnDutyFromResponse = (
  responseData: any,
): PersonnelOnDuty => ({
  name: responseData.name,
  position: responseData.position,
  shift: responseData.shift,
  institutionID: responseData.institution_id,
  departmentID: responseData.department_id,
  departmentSectionID: responseData.department_section_id,
  institutionName: responseData.institution_name,
  departmentName: responseData.department_name,
  departmentSectionName: responseData.department_section_name,
});

export interface PersonnelOnDutyRequest {
  day: Date;
  institutionID?: number;
  departmentID?: number;
  departmentSectionID?: number;
  shift?: Shift;
}

export const personnelOnDutyRequestOutputTransformer = (
  req: PersonnelOnDutyRequest,
) => {
  const params: any = {
    day: DateTime.fromJSDate(req.day).toISODate()!,
    shift: req.shift,
  };

  if (isFinite(req.institutionID)) {
    params.institution_id = req.institutionID;
  }
  if (isFinite(req.departmentID)) {
    params.department_id = req.departmentID;
  }
  if (isFinite(req.departmentSectionID)) {
    params.department_section_id = req.departmentSectionID;
  }
  return params;
};
