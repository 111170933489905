import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { cloneDeep, isEmpty, isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import { useRecoilValue } from 'recoil';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { Department } from '../../interfaces/Department';
import { deleteDepartment, upsertDepartment } from '../../services/Department';
import {
  getDepartmentPersonnelCountsTemplate,
  getDepartmentTemplate,
  validateDepartment,
} from '../../utils/Department';
import NumberInput from '../NumberInput';
import ConfirmDialog from '../ConfirmDialog';
import { Institution } from '../../interfaces/Institution';
import InstitutionDropdown from '../Institution/InstitutionDropdown';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';

interface UpsertDepartmentDialogProps {
  open: boolean;
  clickedDepartment?: Department;
  institutions: Institution[];
  onClose: () => void;
  onModify: () => void;
}

const UpsertDepartmentDialog = (props: UpsertDepartmentDialogProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(getDepartmentTemplate());
  const [totalPersonnelCounts, setTotalPersonnelCounts] = useState(
    getDepartmentPersonnelCountsTemplate(),
  );
  const [clickedDelete, setClickedDelete] = useState(false);

  useEffect(() => {
    setCurrentState(
      props.clickedDepartment
        ? cloneDeep(props.clickedDepartment)
        : getDepartmentTemplate(),
    );

    if (!props.clickedDepartment) {
      setClickedDelete(false);
    }
  }, [props.clickedDepartment, props.open]);

  useEffect(() => {
    if (!props.clickedDepartment && !isEmpty(props.institutions)) {
      setCurrentState({
        ...currentState,
        institutionID: props.institutions[0].ID!,
        institutionName: props.institutions[0].name,
      });
    }
  }, [props.institutions]);

  useEffect(() => {
    setTotalPersonnelCounts({
      day:
        currentState.minimumPersonnelCounts.day +
        currentState.minimumSeniorPersonnelCounts.day,
      night:
        currentState.minimumPersonnelCounts.night +
        currentState.minimumSeniorPersonnelCounts.night,
      weekend:
        currentState.minimumPersonnelCounts.weekend +
        currentState.minimumSeniorPersonnelCounts.weekend,
    });
  }, [currentState]);

  const handleConfirm = () => {
    setLoading(true);

    upsertDepartment(currentState)
      .then(() => {
        toast.success(
          props.clickedDepartment
            ? t('department.successful.update')
            : t('department.successful.add'),
        );
        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    if (!isFinite(props.clickedDepartment?.ID)) {
      return;
    }

    setLoading(true);
    deleteDepartment(props.clickedDepartment!.ID!)
      .then(() => {
        toast.success(t('department.successful.delete'));
        setClickedDelete(false);
        props.onModify();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangeName = (e: any) => {
    setCurrentState({ ...currentState, name: e.target.value });
  };

  const handleChangeInstitution = (newInstitution: Institution) => {
    setCurrentState({
      ...currentState,
      institutionID: newInstitution.ID!,
      institutionName: newInstitution.name,
    });
  };

  const handleChangeMinDayShiftPersonnelCountName = (newValue: number) => {
    setCurrentState({
      ...currentState,
      minimumPersonnelCounts: {
        ...currentState.minimumPersonnelCounts,
        day: newValue,
      },
    });
  };

  const handleChangeMinDayShiftSeniorPersonnelCountName = (
    newValue: number,
  ) => {
    setCurrentState({
      ...currentState,
      minimumSeniorPersonnelCounts: {
        ...currentState.minimumSeniorPersonnelCounts,
        day: newValue,
      },
    });
  };

  const handleChangeMinNightShiftPersonnelCountName = (newValue: number) => {
    setCurrentState({
      ...currentState,
      minimumPersonnelCounts: {
        ...currentState.minimumPersonnelCounts,
        night: newValue,
      },
    });
  };

  const handleChangeMinNightShiftSeniorPersonnelCountName = (
    newValue: number,
  ) => {
    setCurrentState({
      ...currentState,
      minimumSeniorPersonnelCounts: {
        ...currentState.minimumSeniorPersonnelCounts,
        night: newValue,
      },
    });
  };

  const handleChangeMinWeekendShiftPersonnelCountName = (newValue: number) => {
    setCurrentState({
      ...currentState,
      minimumPersonnelCounts: {
        ...currentState.minimumPersonnelCounts,
        weekend: newValue,
      },
    });
  };

  const handleChangeMinWeekendShiftSeniorPersonnelCountName = (
    newValue: number,
  ) => {
    setCurrentState({
      ...currentState,
      minimumSeniorPersonnelCounts: {
        ...currentState.minimumSeniorPersonnelCounts,
        weekend: newValue,
      },
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={false}
      PaperProps={{
        style: {
          width: 650,
        },
      }}
    >
      <ConfirmDialog
        content={t('department.delete.confirm_message')}
        open={clickedDelete}
        onClose={() => setClickedDelete(false)}
        onConfirm={handleDelete}
        color='error'
      />
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {props.clickedDepartment
          ? t('department.dialog.update')
          : t('department.dialog.add')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          component='div'
          sx={{
            mt: 1.5,
            mb: 2.5,
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
          }}
        >
          <TextField
            label={t('department.name')}
            value={currentState.name}
            onChange={handleChangeName}
            size='small'
            error={isEmpty(currentState.name)}
            sx={{ width: 350, mr: 1.5 }}
          />
          <InstitutionDropdown
            label={t('department.institution') as string}
            backgroundColor={sharedColors.white}
            options={props.institutions}
            disabled={!!props.clickedDepartment}
            value={currentState.institutionID}
            onChange={handleChangeInstitution}
          />
        </Box>
        <Grid container columns={14} spacing={1.5}>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Typography sx={{ ...sharedStyles.body1 }}>
              {t('department.min_personnel_count')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ ...sharedStyles.body1 }}>
              {t('department.min_senior_personnel_count')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ ...sharedStyles.body1 }}>
              {t('department.min_total_personnel_count')}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ mt: 'auto', mb: 'auto' }}>
            <Tooltip title={t('department.except_head_nurse')}>
              <Typography sx={{ ...sharedStyles.body1 }}>
                {`${t('department.personnel_count.day')}*`}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={currentState.minimumPersonnelCounts.day}
              onChange={handleChangeMinDayShiftPersonnelCountName}
              error={currentState.minimumPersonnelCounts.day < 0}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={currentState.minimumSeniorPersonnelCounts.day}
              onChange={handleChangeMinDayShiftSeniorPersonnelCountName}
              error={currentState.minimumSeniorPersonnelCounts.day < 0}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={totalPersonnelCounts.day}
              disabled
              error={totalPersonnelCounts.day < 0}
            />
          </Grid>
          <Grid item xs={2} sx={{ mt: 'auto', mb: 'auto' }}>
            <Typography sx={{ ...sharedStyles.body1 }}>
              {t('department.personnel_count.night')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={currentState.minimumPersonnelCounts.night}
              onChange={handleChangeMinNightShiftPersonnelCountName}
              error={currentState.minimumPersonnelCounts.night < 0}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={currentState.minimumSeniorPersonnelCounts.night}
              onChange={handleChangeMinNightShiftSeniorPersonnelCountName}
              error={currentState.minimumSeniorPersonnelCounts.night < 0}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={totalPersonnelCounts.night}
              disabled
              error={totalPersonnelCounts.night < 0}
            />
          </Grid>
          <Grid item xs={2} sx={{ mt: 'auto', mb: 'auto' }}>
            <Typography sx={{ ...sharedStyles.body1 }}>
              {t('department.personnel_count.weekend')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={currentState.minimumPersonnelCounts.weekend}
              onChange={handleChangeMinWeekendShiftPersonnelCountName}
              error={currentState.minimumPersonnelCounts.weekend < 0}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={currentState.minimumSeniorPersonnelCounts.weekend}
              onChange={handleChangeMinWeekendShiftSeniorPersonnelCountName}
              error={currentState.minimumSeniorPersonnelCounts.weekend < 0}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              value={totalPersonnelCounts.weekend}
              disabled
              error={totalPersonnelCounts.weekend < 0}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        {hasWriteAccess(tokenData, AccessLevel.INSTITUTION) && (
          <Button
            variant='contained'
            color='error'
            disabled={!props.clickedDepartment}
            onClick={() => setClickedDelete(true)}
            sx={sharedStyles.buttonText}
          >
            {t('shared.delete')}
          </Button>
        )}
        <Box component='div' sx={{ display: 'flex', flexGrow: 1 }} />
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!validateDepartment(currentState)}
          onClick={handleConfirm}
          sx={sharedStyles.buttonText}
        >
          {props.clickedDepartment
            ? t('department.dialog.update')
            : t('department.dialog.add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertDepartmentDialog;
