import { isArray } from 'lodash';
import {
  ScheduleNotes,
  scheduleNotesFromResponse,
} from '../interfaces/ScheduleNotes';
import api from './Api';
import { uploadDocuments } from './Document';

const scheduleNotesBaseUrl = '/schedule-notes/';

export const addScheduleNotes = async (
  scheduleID: number,
  notes: string,
  files: File[],
): Promise<void> => {
  const attachments = await uploadDocuments(files);

  await api.post(`${scheduleNotesBaseUrl}comment`, {
    schedule_id: scheduleID,
    notes,
    attachments,
  });
};

export const getScheduleNotes = async (
  scheduleID: number,
): Promise<ScheduleNotes[]> => {
  const response = await api.get(scheduleNotesBaseUrl + scheduleID.toString());

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((notes) => scheduleNotesFromResponse(notes));
};
