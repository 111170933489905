import { useState } from 'react';
import { isFinite } from 'lodash';
import { TextField } from '@mui/material';

interface NumberInputProps {
  label?: string;
  value?: number;
  onChange?: (newValue: number) => void;
  onClick?: () => void;
  acceptFloat?: boolean;
  precision?: number;
  disabled?: boolean;
  error?: boolean;
  endAdornment?: JSX.Element;
}

const NumberInput = (props: NumberInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [plainTextValue, setPlainTextValue] = useState('');

  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(
        props.acceptFloat
          ? parseFloat(e.target.value)
          : parseInt(e.target.value, 10),
      );
    }
    setPlainTextValue(e.target.value);
  };

  const getFormattedValue = (): string | undefined => {
    if (isFocused) {
      return plainTextValue;
    }

    if (isFinite(props.value)) {
      return props.acceptFloat
        ? props.value?.toFixed(props.precision ?? 2)
        : props.value?.toString();
    }
    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPlainTextValue(getFormattedValue() ?? '');
  };

  return (
    <TextField
      label={props.label}
      size='small'
      variant='outlined'
      type='number'
      value={getFormattedValue() ?? ''}
      onBlur={() => setIsFocused(false)}
      onClick={props.onClick}
      onChange={handleChange}
      onFocus={handleFocus}
      sx={{ display: 'flex', flexGrow: 1 }}
      disabled={props.disabled}
      InputProps={
        props.endAdornment ? { endAdornment: props.endAdornment } : undefined
      }
      error={props.error}
    />
  );
};

export default NumberInput;
