import { Autocomplete, TextField } from '@mui/material';
import { Department } from '../../interfaces/Department';

interface DepartmentAutocompleteProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  error?: boolean;
  options: Department[];
  value: Department | null;
  onChange: (newDepartment: Department | null) => void;
}

const DepartmentAutocomplete = (props: DepartmentAutocompleteProps) => (
  <Autocomplete
    renderInput={(params) => (
      <TextField
        {...params}
        label={props.label}
        variant='outlined'
        size='small'
        error={props.error}
        sx={{
          width: props.width ?? 250,
          backgroundColor: props.backgroundColor,
        }}
        disabled={props.disabled}
      />
    )}
    options={props.options}
    getOptionLabel={(department) => department.name}
    value={props.value}
    onChange={(_, newDepartment) => props.onChange(newDepartment)}
    disabled={props.disabled}
  />
);

export default DepartmentAutocomplete;
