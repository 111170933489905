import { Personnel, personnelFromResponse } from './Personnel';
import { GroupStatus } from './GroupStatus';

export interface DepartmentSection {
  ID?: number;
  institutionID: number;
  institutionName: string;
  departmentID: number;
  departmentName: string;
  name: string;
  personnels: Personnel[];
  status?: GroupStatus;
}

export const departmentSectionFromResponse = (
  responseData: any,
): DepartmentSection => ({
  ID: responseData.id,
  institutionID: responseData.institution_id,
  institutionName: responseData.institution_name,
  departmentID: responseData.department_id,
  departmentName: responseData.department_name,
  name: responseData.name,
  personnels: responseData.personnels.map((personnel: any) =>
    personnelFromResponse(personnel),
  ),
  status: responseData.status,
});

export const departmentSectionOutputTransformer = (
  departmentSection: DepartmentSection,
) => ({
  id: departmentSection.ID,
  institution_id: departmentSection.institutionID,
  institution_name: departmentSection.institutionName,
  department_id: departmentSection.departmentID,
  department_name: departmentSection.departmentName,
  name: departmentSection.name,
});
