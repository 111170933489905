import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { changePassword } from '../../services/User';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { validatePassword } from '../../utils/Password';

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setPassword('');
    setConfirmPassword('');
  }, [props.open]);

  const handleConfirm = () => {
    setLoading(true);

    changePassword(password)
      .then(() => {
        toast.success(t('user.successful.change_password'));
        props.onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        {t('user.change_password')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip title={t('user.password_tooltip')}>
          <TextField
            label={t('user.password')}
            value={password}
            onChange={handleChangePassword}
            size='small'
            type={showPassword ? undefined : 'password'}
            error={!validatePassword(password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    color={showPassword ? 'primary' : 'inherit'}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: 230, mt: 1.5 }}
          />
        </Tooltip>
        <TextField
          label={t('user.confirm_password')}
          value={confirmPassword}
          onChange={handleChangeConfirmPassword}
          size='small'
          type='password'
          error={password !== confirmPassword}
          sx={{ width: 230, mt: 1.5 }}
        />
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={!validatePassword(password) || password !== confirmPassword}
          onClick={handleConfirm}
          sx={sharedStyles.buttonText}
        >
          {t('shared.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
