import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';

interface SecureComponentProps {
  children: JSX.Element;
}

const SecureComponent = (props: SecureComponentProps) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak, initialized]);

  return keycloak.authenticated ? props.children : <></>;
};

export default SecureComponent;
