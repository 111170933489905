import { isFinite } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { sharedColors } from '../../utils/Style';
import { turkishCities } from '../../variables/City';

interface CityDropdownProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  required?: boolean;
  value: string;
  onChange: (newValue: string) => void;
}

const CityDropdown = (props: CityDropdownProps) => {
  const handleChange = (e: any) => {
    props.onChange(e.target.value);
  };

  return (
    <FormControl
      size='small'
      fullWidth={!isFinite(props.width)}
      sx={{
        width: props.width,
        backgroundColor: props.backgroundColor ?? sharedColors.white,
      }}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={props.value}
        label={props.label}
        disabled={props.disabled}
        onChange={handleChange}
        name='select'
        required={props.required}
      >
        {turkishCities.map((city) => (
          <MenuItem value={city.name} key={city.name}>
            {city.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CityDropdown;
