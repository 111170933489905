import { PersonnelOnDuty } from '../interfaces/PersonnelOnDuty';
import { getStoredLanguage } from './Language';
import { getPersonnelPositionWeight } from './Personnel';

export const comparePersonnelsOnDuty = (
  p1: PersonnelOnDuty,
  p2: PersonnelOnDuty,
  fieldName: keyof PersonnelOnDuty,
): number => {
  const locale = getStoredLanguage();

  switch (fieldName) {
    case 'institutionID':
      return p1.institutionID - p2.institutionID;
    case 'institutionName':
      return p1.institutionName.localeCompare(p2.institutionName, locale);
    case 'departmentID':
      return p1.departmentID - p2.departmentID;
    case 'departmentSectionID':
      return (p1.departmentSectionID ?? 0) - (p2.departmentSectionID ?? 0);
    case 'departmentName':
      return p1.departmentName.localeCompare(p2.departmentName, locale);
    case 'departmentSectionName':
      return (p1.departmentSectionName ?? '').localeCompare(
        p2.departmentSectionName ?? '',
        locale,
      );
    case 'name':
      return p1.name.localeCompare(p2.name, locale);
    case 'shift':
      return p1.shift.localeCompare(p2.shift, locale);
    case 'position':
      return (
        getPersonnelPositionWeight(p1.position) -
        getPersonnelPositionWeight(p2.position)
      );
    default:
      return p1.name.localeCompare(p2.name, locale);
  }
};
