import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';
import { flatten } from 'lodash';
import {
  OvertimeChangeNotes,
  ShiftChangeNotes,
} from '../../interfaces/ShiftChangeNotes';
import { formatLocalTime } from '../../utils/Date';
import { formatToLimitedDecimals } from '../../utils/Decimals';

interface ShiftChangeTooltipProps {
  shiftChangeNotes: ShiftChangeNotes[];
  overtimeChangeNotes: OvertimeChangeNotes[];
  children: ReactElement<any, any>;
}

interface TooltipEntry {
  rows: (ReactElement<any, any> | string)[];
  date: Date;
}

const ShiftChangeTooltip = (props: ShiftChangeTooltipProps) => {
  const { t } = useTranslation();

  const entries: TooltipEntry[] = [
    ...props.shiftChangeNotes.map((changeNotes) => ({
      rows: [
        t('schedule.update_notes.tooltip_audit', {
          date: formatLocalTime(t, changeNotes.changedAt),
          username: changeNotes.changedBy,
        }) as string,
        <br />,
        t('schedule.update_notes.change_notes', {
          before: t(`schedule.shifts.${changeNotes.before ?? 'EMPTY'}`),
          after: t(`schedule.shifts.${changeNotes.after ?? 'EMPTY'}`),
          notes: changeNotes.notes,
        }) as string,
        <br />,
        <br />,
      ],
      date: changeNotes.changedAt,
    })),
    ...props.overtimeChangeNotes.map((changeNotes) => ({
      rows: [
        t('schedule.update_notes.tooltip_audit', {
          date: formatLocalTime(t, changeNotes.changedAt),
          username: changeNotes.changedBy,
        }) as string,
        <br />,
        t('schedule.update_notes.overtime_notes', {
          before: formatToLimitedDecimals(changeNotes.before, 1),
          after: formatToLimitedDecimals(changeNotes.after, 1),
          notes: changeNotes.notes,
        }) as string,
        <br />,
        <br />,
      ],
      date: changeNotes.changedAt,
    })),
  ];
  entries.sort((a, b) => (a.date < b.date ? -1 : 1));
  let rows = flatten(entries.map((entry) => entry.rows));
  if (rows.length > 2) {
    rows = rows.slice(0, rows.length - 2);
  }

  return (
    <Tooltip title={<Typography>{rows}</Typography>}>{props.children}</Tooltip>
  );
};

export default ShiftChangeTooltip;
