import { isArray } from 'lodash';
import {
  Department,
  departmentFromResponse,
  departmentOutputTransformer,
} from '../interfaces/Department';
import api from './Api';

const departmentBaseUrl = '/department/';

export const upsertDepartment = async (department: Department): Promise<void> =>
  api.put(departmentBaseUrl, departmentOutputTransformer(department));

export const getDepartments = async (): Promise<Department[]> => {
  const response = await api.get(departmentBaseUrl);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((department) => departmentFromResponse(department));
};

export const deleteDepartment = async (departmentID: number): Promise<void> =>
  api.delete(departmentBaseUrl + departmentID.toString());
