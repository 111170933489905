import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PersonnelPosition } from '../../interfaces/Personnel';

interface PersonnelPositionDropdownProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  allowSelectAll?: boolean;
  required?: boolean;
  value: PersonnelPosition;
  onChange: (newPosition: PersonnelPosition) => void;
}

const PersonnelPositionDropdown = (props: PersonnelPositionDropdownProps) => {
  const { t } = useTranslation();

  return (
    <FormControl
      size='small'
      sx={{ width: props.width ?? 250, backgroundColor: props.backgroundColor }}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={props.value}
        label={props.label}
        disabled={props.disabled}
        onChange={(e) => props.onChange(e.target.value as PersonnelPosition)}
        name='select'
        required={props.required}
      >
        {props.allowSelectAll && (
          <MenuItem value={PersonnelPosition.ALL} key={PersonnelPosition.ALL}>
            {t('personnel.all_positions')}
          </MenuItem>
        )}
        <MenuItem value={PersonnelPosition.NURSE} key={PersonnelPosition.NURSE}>
          {t('personnel.nurse')}
        </MenuItem>
        <MenuItem
          value={PersonnelPosition.SENIOR_NURSE}
          key={PersonnelPosition.SENIOR_NURSE}
        >
          {t('personnel.senior_nurse')}
        </MenuItem>
        <MenuItem
          value={PersonnelPosition.HEAD_NURSE}
          key={PersonnelPosition.HEAD_NURSE}
        >
          {t('personnel.head_nurse')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default PersonnelPositionDropdown;
