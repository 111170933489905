import { Shift } from './Shift';
import { Audit, auditFromResponse } from './Audit';

export interface ScheduleShiftChange {
  personnelName: string;
  dateStr: string;
  before?: Shift;
  after?: Shift;
}

export const scheduleShiftChangeFromResponse = (
  responseData: any,
): ScheduleShiftChange => ({
  personnelName: responseData.personnel_name,
  dateStr: responseData.date_str,
  before: responseData.before,
  after: responseData.after,
});

export interface ScheduleOvertimeChange {
  personnelName: string;
  dateStr: string;
  before: number;
  after: number;
}

export const scheduleOvertimeChangeFromResponse = (
  responseData: any,
): ScheduleOvertimeChange => ({
  personnelName: responseData.personnel_name,
  dateStr: responseData.date_str,
  before: responseData.before,
  after: responseData.after,
});

export enum ScheduleNotesType {
  COMMENT = 'COMMENT',
  CHANGES = 'CHANGES',
  APPROVAL = 'APPROVAL',
  REJECTION = 'REJECTION',
  APPROVAL_ROLLBACK = 'APPROVAL_ROLLBACK',
}

export interface ScheduleNotes {
  id: number;
  scheduleID: number;
  type: ScheduleNotesType;
  notes: string;
  attachments: string[];
  changes: ScheduleShiftChange[];
  overtimeChanges: ScheduleOvertimeChange[];
  audit: Audit;
}

export const scheduleNotesFromResponse = (
  responseData: any,
): ScheduleNotes => ({
  id: responseData.id,
  scheduleID: responseData.schedule_id,
  type: responseData.type,
  notes: responseData.notes,
  attachments: responseData.attachments,
  changes: (responseData.changes ?? []).map((c: any) =>
    scheduleShiftChangeFromResponse(c),
  ),
  overtimeChanges: (responseData.overtime_changes ?? []).map((c: any) =>
    scheduleOvertimeChangeFromResponse(c),
  ),
  audit: auditFromResponse(responseData.audit),
});
