import {
  PtoCategory,
  PtoSubtype,
  PtoSubtypeByCategory,
} from '../interfaces/PtoSubtype';

export const ptoSubtypesByCategory: PtoSubtypeByCategory = {
  [PtoCategory.ANNUAL]: [PtoSubtype.ANNUAL],
  [PtoCategory.EXCUSED]: [
    PtoSubtype.SPECIAL_REQUEST,
    PtoSubtype.NON_PAID,
    PtoSubtype.ON_DUTY,
    PtoSubtype.EXCUSED,
    PtoSubtype.EDUCATIONAL,
    PtoSubtype.POST_BIRTH,
    PtoSubtype.INSTITUTIONAL_INTERNAL,
    PtoSubtype.COURT,
    PtoSubtype.MEDICAL_RESCUE,
    PtoSubtype.PAID_COMPANION,
    PtoSubtype.THIRTY_TWO_WEEKS,
    PtoSubtype.DEATH,
    PtoSubtype.MARRIAGE,
    PtoSubtype.BIRTH,
    PtoSubtype.COMPANION,
    PtoSubtype.PATERNITY,
    PtoSubtype.PRE_BIRTH,
    PtoSubtype.UNION,
    PtoSubtype.WEEKEND,
    PtoSubtype.PART_TIME,
    PtoSubtype.PARTIAL_TIME,
    PtoSubtype.SEARCHING_FOR_JOB,
  ],
  [PtoCategory.HAZARD]: [
    PtoSubtype.MEDICAL_COMMITTEE_REPORT,
    PtoSubtype.DISPATCH,
    PtoSubtype.ACCOMPANIED,
    PtoSubtype.WITHOUT_REPORT,
    PtoSubtype.DOCTOR_REPORT,
    PtoSubtype.OCCUPATIONAL_HAZARD,
    PtoSubtype.ON_DUTY_ASSAULT,
    PtoSubtype.HOSPITALIZATION,
    PtoSubtype.COVID_19_QUARANTINE,
    PtoSubtype.COVID_19_TREATMENT,
    PtoSubtype.COVID_19_CONTACT,
    PtoSubtype.MEDICAL_INSPECTION,
  ],
  [PtoCategory.CONGRESS]: [
    PtoSubtype.DOMESTIC_CONGRESS,
    PtoSubtype.INTERNATIONAL_CONGRESS,
  ],
  [PtoCategory.DUTY]: [
    PtoSubtype.ARTICLE_THIRTY_NINE_NO_NOTIFICATION,
    PtoSubtype.ARTICLE_THIRTY_NINE_DIRECTORIAL,
    PtoSubtype.APPROVED_DUTY,
    PtoSubtype.NON_APPROVED_DUTY,
  ],
};

export const availablePtoCategories: PtoCategory[] = [
  PtoCategory.ANNUAL,
  PtoCategory.EXCUSED,
  PtoCategory.HAZARD,
  PtoCategory.CONGRESS,
  PtoCategory.DUTY,
];
