import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'react-mui-dropzone';
import { createStyles, makeStyles } from '@mui/styles';
import { sharedColors, sharedStyles } from '../../utils/Style';

interface UploadNoteAttachmentDialogProps {
  open: boolean;
  files: File[];
  onClose: () => void;
  onConfirm: (newFiles: File[]) => void;
}

const UploadNoteAttachmentDialog = (props: UploadNoteAttachmentDialogProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('schedule.notes_dialog.upload_attachments')}</DialogTitle>
      <DialogContent>
        <FormControl component='fieldset' sx={{ display: 'flex', flexGrow: 1 }}>
          <DropzoneArea
            initialFiles={files}
            dropzoneClass={classes.dropzone}
            dropzoneText={t('upload.title_multiple') as string}
            filesLimit={100}
            showFileNames
            onChange={setFiles}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          {t('confirm_dialog.cancel')}
        </Button>
        <Button
          variant='contained'
          onClick={() => props.onConfirm(files)}
          autoFocus
          color='primary'
          sx={sharedStyles.buttonText}
        >
          {t('confirm_dialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    dropzone: {
      position: 'relative',
      padding: 2,
      minHeight: '100px',
      backgroundColor: sharedColors.gray2,
      border: 'dashed',
      borderColor: sharedColors.gray3,
      cursor: 'pointer',
    },
  }),
);

export default UploadNoteAttachmentDialog;
