import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';
import { flatten } from 'lodash';
import {
  ScheduleOvertimeChange,
  ScheduleShiftChange,
} from '../../interfaces/ScheduleNotes';
import { formatToLimitedDecimals } from '../../utils/Decimals';

interface ChangeNotesTooltipProps {
  shiftChanges: ScheduleShiftChange[];
  overtimeChanges: ScheduleOvertimeChange[];
  children: ReactElement<any, any>;
}

const ChangeNotesTooltip = (props: ChangeNotesTooltipProps) => {
  const { t } = useTranslation();

  let rows = flatten([
    ...props.shiftChanges.map((change) => [
      t('schedule.notes_dialog.change_tooltip.personnel_name', {
        name: change.personnelName,
      }),
      <br />,
      t('schedule.notes_dialog.change_tooltip.date', {
        date: change.dateStr,
      }),
      <br />,
      t('schedule.notes_dialog.change_tooltip.shift_change', {
        before: t(`schedule.shifts.${change.before ?? 'EMPTY'}`),
        after: t(`schedule.shifts.${change.after ?? 'EMPTY'}`),
      }),
      <br />,
      <br />,
    ]),
    ...props.overtimeChanges.map((change) => [
      t('schedule.notes_dialog.change_tooltip.personnel_name', {
        name: change.personnelName,
      }),
      <br />,
      t('schedule.notes_dialog.change_tooltip.date', {
        date: change.dateStr,
      }),
      <br />,
      t('schedule.notes_dialog.change_tooltip.overtime_change', {
        before: formatToLimitedDecimals(change.before, 1),
        after: formatToLimitedDecimals(change.after, 1),
      }),
      <br />,
      <br />,
    ]),
  ]);
  if (rows.length > 2) {
    rows = rows.slice(0, rows.length - 2);
  }

  return (
    <Tooltip title={<Typography>{rows}</Typography>}>{props.children}</Tooltip>
  );
};

export default ChangeNotesTooltip;
