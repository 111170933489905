import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isFinite } from 'lodash';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Institution } from '../../interfaces/Institution';
import { Pagination } from '../../interfaces/Pagination';
import { SortCriteria } from '../../interfaces/SortCriteria';
import { deleteInstitution } from '../../services/Institution';
import { getStoredLanguage } from '../../utils/Language';
import { compareInstitutions } from '../../utils/Institution';
import { getPriorityByOrder } from '../../utils/Sort';
import { sharedColors, sharedStyles } from '../../utils/Style';
import Waiting from '../Waiting';
import ConfirmDialog from '../ConfirmDialog';
import UpsertInstitutionDialog from './UpsertInstitutionDialog';
import SortableTableCell from '../SortableTableCell';
import { tokenDataAtom } from '../../atoms/TokenData';
import { hasWriteAccess } from '../../utils/TokenData';
import { AccessLevel } from '../../interfaces/User';

interface InstitutionTableProps {
  institutions: Institution[];
  onChange: () => void;
}

const InstitutionTable = (props: InstitutionTableProps) => {
  const { t } = useTranslation();

  const tokenData = useRecoilValue(tokenDataAtom);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [searchedValue, setSearchedValue] = useState('');
  const [clickedInstitution, setClickedInstitution] = useState<
    Institution | undefined
  >(undefined);
  const [showNewInstitutionDialog, setShowNewInstitutionDialog] =
    useState(false);
  const [institutionToDelete, setInstitutionToDelete] = useState<
    Institution | undefined
  >(undefined);
  const [sortCriteria, setSortCriteria] = useState<SortCriteria<Institution>>({
    fieldName: 'name',
    asc: true,
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      pageNumber: 0,
    });
  }, [searchedValue]);

  const handleSearch = (e: any) => {
    setSearchedValue(e.target.value ?? '');
  };

  const handleCloseUpsertDialog = () => {
    setShowNewInstitutionDialog(false);
    setClickedInstitution(undefined);
  };

  const handleUpsert = () => {
    handleCloseUpsertDialog();
    props.onChange();
  };

  const handleDelete = () => {
    if (!isFinite(institutionToDelete?.ID)) {
      return;
    }

    setLoading(true);
    deleteInstitution(institutionToDelete!.ID!)
      .then(() => {
        toast.success(t('institution.successful.delete'));
        setInstitutionToDelete(undefined);
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleSort = (fieldName: keyof Institution) => {
    setSortCriteria({
      fieldName,
      asc: fieldName === sortCriteria.fieldName ? !sortCriteria.asc : true,
    });
  };

  const locale = getStoredLanguage();

  let filteredRows = props.institutions.filter((institution) =>
    institution.name
      .toLocaleLowerCase(locale)
      .includes(searchedValue.toLocaleLowerCase(locale)),
  );

  filteredRows = filteredRows.sort((i1, i2) =>
    compareInstitutions(i1, i2, sortCriteria.fieldName) < 0
      ? getPriorityByOrder(sortCriteria.asc)
      : -getPriorityByOrder(sortCriteria.asc),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  const readOnly = !hasWriteAccess(tokenData, AccessLevel.ADMIN);

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Waiting open={loading} />
      <ConfirmDialog
        content={t('institution.delete.confirm_message')}
        open={!!institutionToDelete}
        onClose={() => setInstitutionToDelete(undefined)}
        onConfirm={handleDelete}
        color='error'
      />
      {!readOnly && (
        <UpsertInstitutionDialog
          open={showNewInstitutionDialog || !!clickedInstitution}
          clickedInstitution={clickedInstitution}
          onClose={handleCloseUpsertDialog}
          onModify={handleUpsert}
        />
      )}
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {t('institution.title')}
      </Typography>
      <Box
        component='div'
        sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', mt: 1.5 }}
      >
        <TextField
          id='search-text-field'
          size='small'
          label={t('shared.search')}
          variant='outlined'
          value={searchedValue}
          onChange={handleSearch}
          sx={{ width: 300, backgroundColor: sharedColors.white, mr: 1.5 }}
        />
        <Box component='div' sx={{ flexGrow: 1 }} />
        {!readOnly && (
          <Button
            variant='contained'
            color='primary'
            onClick={() => setShowNewInstitutionDialog(true)}
            sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto' }}
          >
            {t('institution.new')}
          </Button>
        )}
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='name'
                onSort={() => handleSort('name')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('institution.name')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='city'
                onSort={() => handleSort('city')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('institution.city')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='departments'
                onSort={() => handleSort('departments')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('institution.department_count')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='totalDepartmentSections'
                onSort={() => handleSort('totalDepartmentSections')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('institution.department_section_count')}
                </Typography>
              </SortableTableCell>
              <SortableTableCell
                sortCriteria={sortCriteria}
                fieldName='totalPersonnels'
                onSort={() => handleSort('totalPersonnels')}
              >
                <Typography sx={sharedStyles.columnLabel}>
                  {t('institution.personnel_count')}
                </Typography>
              </SortableTableCell>
              {!readOnly && (
                <TableCell align='center'>
                  <Typography sx={sharedStyles.columnLabel}>
                    {t('shared.action')}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <TableRow
                key={row.ID}
                sx={readOnly ? undefined : sharedStyles.editableRow}
              >
                <TableCell onClick={() => setClickedInstitution(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedInstitution(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.city}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedInstitution(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.totalDepartments.toString()}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedInstitution(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.totalDepartmentSections.toString()}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedInstitution(row)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {row.totalPersonnels.toString()}
                  </Typography>
                </TableCell>
                {!readOnly && (
                  <TableCell align='center'>
                    <Button
                      onClick={() => setInstitutionToDelete(row)}
                      color='secondary'
                      sx={{
                        ...sharedStyles.buttonText,
                        p: 0,
                        minWidth: 0,
                      }}
                    >
                      {t('shared.delete')}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage={t('shared.pagination.rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            t('shared.pagination.from_to_count', { from, to, count })
          }
        />
      </TableContainer>
    </Box>
  );
};

export default InstitutionTable;
