import { isString } from 'lodash';
import {
  UserCreateRequest,
  userCreateRequestOutputTransformer,
  UserGetRequest,
  userGetRequestOutputTransformer,
  UsersResponse,
  usersResponseFromResponse,
  UserUpdateRequest,
  userUpdateRequestOutputTransformer,
} from '../interfaces/User';
import api from './Api';

const userBaseUrl = '/user/';

export const createUser = async (req: UserCreateRequest): Promise<void> =>
  api.post(userBaseUrl, userCreateRequestOutputTransformer(req));

export const getUsers = async (req: UserGetRequest): Promise<UsersResponse> => {
  const response = await api.get(userBaseUrl, {
    params: userGetRequestOutputTransformer(req),
    paramsSerializer: { indexes: null },
  });

  return usersResponseFromResponse(response.data);
};

export const updateUser = async (
  userID: string,
  req: UserUpdateRequest,
): Promise<void> =>
  api.put(userBaseUrl + userID, userUpdateRequestOutputTransformer(req));

export const deleteUser = async (userID: string): Promise<void> =>
  api.delete(userBaseUrl + userID);

export const changePassword = async (newPassword: string): Promise<void> =>
  api.patch(`${userBaseUrl}password`, { new_password: newPassword });

export const resetPassword = async (userID: string): Promise<string> => {
  const response = await api.post(`${userBaseUrl}password/reset/${userID}`);

  const newPassword = response.data?.new_password;
  if (!newPassword || !isString(newPassword)) {
    throw new Error('New password is not present in the response');
  }

  return newPassword;
};
